import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import { getUploadedFilesPreview } from 'services/utilities';
import { useState, useEffect } from 'react';
import Dropzone from '../../common/drop-zone/DropZone';
import './UpdateQuote.css';
import CustomDatePicker from '../../common/date-picker/DatePicker';
import { authorizedPutCall } from '../../services/APIsService';
import { getOneQuote } from '../../services/adminService';
import SpinnerLoader from 'components/Misc/Spinner';
import { uploadFiles } from 'services/s3Service';
import OneButtonModal from 'components/Modals/one-button-modal/OneButtonModal';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { TENDER_DETAIL_TABS, TRANSPORT } from '../../constants';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import CurrencyInput from 'react-currency-input-field';
import toast, { Toaster } from 'react-hot-toast';
import { errorToast, successToast } from '../../shared/constants';

function UpdateQuote() {
  const { id } = useParams();
  const [quote, setQuote] = useState(null);
  const [files, setFiles] = useState(null);
  const [payDirect, setPayDirect] = useState(true);
  const [showSpinner, setSpinner] = useState(false);
  const [price, setPrice] = useState();

  const [resolvedModal, setResolvedModal] = useState(false);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
    getValues,
  } = useForm();

  const getQuote = async () => {
    try {
      setSpinner(true);
      const response = await getOneQuote(id);
      if (response.error) {
        toast.error('something went wrong', errorToast);
      } else {
        setQuote(response);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('something went wrong', errorToast);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getQuote();
  }, []);

  useEffect(() => {
    if (quote) {
      setValue('price', quote.price);
      setValue('transport', quote.transport);
      setValue(
        'completion_date',
        quote.completion_date ? new Date(quote.completion_date) : ''
      );
      setValue(
        'quote_validity',
        quote.quote_validity ? new Date(quote.quote_validity) : ''
      );
      setValue('details', quote.details || '');
      setValue('payment_terms', quote.payment_terms || '');
      setPrice(quote.price);
      if (quote.post_code) {
        setValue('post_code', quote.post_code);
      }
      setFiles(
        quote.attachments ? getUploadedFilesPreview(quote.attachments) : []
      );
    }
  }, [quote, price]);

  const handleClose = () => {
    setResolvedModal(false);
  };

  const onSubmit = async (data) => {
    setSpinner(true);

    try {
      let urls = await uploadFiles(files, 'quotes/');
      urls.splice(0, quote.attachments.length);
      let newUrls = [...quote.attachments, ...urls];
      let newData = { ...data };
      newData.attachments = newUrls;
      const response = await authorizedPutCall(
        `/quote/quote-update/${id}`,
        newData
      );
      console.log(response);
      if (response.error) {
        toast.error('Something Went Wrong', errorToast);
      } else {
        setResolvedModal(true);
      }
    } catch (error) {
      toast.error('Something Went Wrong', errorToast);
    } finally {
      setSpinner(false);
    }
  };

  const filesChange = async (newFile) => {
    setFiles(newFile);
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <div className='route-text'>
        <span style={{ color: 'rgb(209, 204, 204)' }}>
          Listings - Listing Details - Quotes Listing
        </span>{' '}
        - Edit Details
      </div>
      <div className='mt-5 listing-container-update-page form-box footer-space'>
        <p>Edit Quote</p>
        <hr />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className='mb-5 pt-4'>
            <Col sm='12'>
              <h2>Quote Details</h2>
            </Col>
          </Row>
          <Row className='mb-4 pl-3'>
            <label className='mb-2 d-block'>
              Transport Option <span className='required-color'>*</span>
            </label>
            <Col sm='12'>
              <label>
                <input
                  className='mx-2 primary-checkbox'
                  type='radio'
                  value='Delivery'
                  {...register('transport', { required: true })}
                />
                Deliver to Farm
              </label>

              <label className='px-3'>
                <input
                  className='mx-2 primary-checkbox'
                  type='radio'
                  value='Pickup'
                  {...register('transport', { required: true })}
                />
                Pickup
              </label>
            </Col>
          </Row>

          <Row className='mb-4'>
            {watch('transport') === TRANSPORT.pickup && (
              <Col sm='12' md='6'>
                <label className='mb-2 d-block'>
                  Pickup Location
                  <span className='required-color'>*</span>
                </label>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name='post_code'
                  render={({ field: { onChange } }) => (
                    <ReactGoogleAutocomplete
                      placeholder='Enter Location'
                      className={`form-input w-100 ${
                        errors['post_code'] && 'invalid-input'
                      }`}
                      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                      onPlaceSelected={(place) => {
                        onChange(place.formatted_address);
                      }}
                      defaultValue={quote?.post_code || ''}
                      options={{
                        types: ['geocode', 'establishment'],
                        componentRestrictions: { country: 'aus' },
                      }}
                    />
                  )}
                />
              </Col>
            )}
            <Col sm='12' md='6'>
              <label className='mb-2 d-block'>
                {watch('transport')} Date
                <span className='required-color'>*</span>
              </label>
              <CustomDatePicker
                className={`form-input w-100 ${
                  errors['completion_date'] && 'invalid-input'
                }`}
                name='completion_date'
                placeholder={'Select date'}
                control={control}
                setValue={setValue}
                required={true}
              />
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col sm='12'>
              <div className='d-flex align-items-center justify-content-between'>
                <label className='mb-2 d-inline-block'>
                  Order/Product Details
                </label>
                <small className='secondary-text-color'>(Optional)</small>
              </div>
              <textarea
                placeholder='Enter details'
                className={`form-input w-100 ${
                  errors['details'] && 'invalid-input'
                }`}
                rows={'5'}
                {...register('details')}
              />
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col sm='12' md='6'>
              <label className='mb-2 d-block'>
                Total Price (Includes GST){' '}
                <span className='required-color'>*</span>
              </label>
              <Controller
                control={control}
                rules={{ required: true }}
                name='price'
                defaultValue={quote?.price} // Set defaultValue here
                render={({ field: { onChange, value } }) => (
                  <CurrencyInput
                    className={`form-input w-100 ${
                      errors['price'] && 'invalid-input'
                    }`}
                    prefix='$'
                    placeholder='Enter amount'
                    value={value} // Use value instead of defaultValue
                    decimalsLimit={2}
                    onValueChange={onChange}
                  />
                )}
              />
            </Col>
          </Row>

          {/*****************  New Field ****************/}
          <Row className='mb-4'>
            <Col sm='12' md='6' className='pt-3 pt-md-0'>
              <label className='mb-2 d-block'>
                Quote Valid until <span className='required-color'>*</span>
              </label>
              <CustomDatePicker
                className={`form-input w-100 ${
                  errors['quote_validity'] && 'invalid-input'
                }`}
                name='quote_validity'
                placeholder={'Select date'}
                control={control}
                setValue={setValue}
                required={true}
              />
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col sm='12'>
              <div className='d-flex align-items-center justify-content-between'>
                <label className='mb-2 d-inline-block'>
                  Payment Terms <span className='required-color'>*</span>
                </label>
              </div>
              <textarea
                placeholder='Enter details'
                className={`form-input w-100 ${
                  errors['payment_terms'] && 'invalid-input'
                }`}
                rows={'5'}
                {...register('payment_terms', { required: true })}
              />
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col sm='12'>
              <div className='d-flex align-items-center justify-content-between'>
                <div>
                  <label className='mb-2 d-inline-block'>Attach Files</label>
                </div>
                <small className='secondary-text-color'>(Optional)</small>
              </div>
              <Dropzone onChange={filesChange} value={files} />
            </Col>
          </Row>

          {/* btns*********************** */}

          <Row className='mb-4 d-flex justify-content-end'>
            <button
              className='btn cancel-btn'
              onClick={() => {
                history.push('/admin/listings');
              }}
            >
              cancel
            </button>
            <button type='submit' className='btn success-btn next-btn'>
              Update Changes
            </button>
          </Row>
        </form>
      </div>
      <OneButtonModal
        show={resolvedModal}
        btn={'close'}
        title={'Quote Updated'}
        message={'Quote has been updated successfully.'}
        click={handleClose}
      />
      <Toaster />
    </>
  );
}

export default UpdateQuote;
