import React from 'react';
import './Modal.css';
import { Modal, Button, closeButton } from 'react-bootstrap';

const TwoButtonModal = ({
  icon,
  title,
  text,
  btn1,
  btn2,
  handleClick1,
  handleClick2,
  handleClose,
  showModal,
  backgroundColor,
}) => {
  return (
    <Modal show={showModal} onHide={handleClose} className="mt-5">
      <Modal.Header className="d-flex justify-content-between align-items-center"></Modal.Header>
      <Modal.Body className="">
        <div className="text-center">
          <img src={icon} alt="" className="mb-2" />
          <div className="modal-title my-2">{title}</div>
          <div className="modal-text">{text}</div>
        </div>
        <div className="text-center my-3">
          {btn1 && (
            <Button
              className="btn btn-white text-dark border border-dark btn-1"
              onClick={handleClick1}
            >
              {btn1}
            </Button>
          )}
          {btn2 && (
            <Button
              className="btn text-white btn-2"
              onClick={handleClick2}
              style={{ backgroundColor: backgroundColor }}
            >
              {btn2}
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TwoButtonModal;
