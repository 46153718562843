import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import './AttachmentCard.css';
import {
  bytesToSize,
  downloadFiles,
  getFileNameFromURL,
  getFilePreview,
} from "../../../../services/utilities"

const AttachmentCard = ({ attachment, setSpinner }) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    const getPreview = async () => {
      const url = await getFilePreview(attachment, false);
      setUrl(url);
    };
    getPreview();
  }, [attachment]);

  const handleDownload = async () => {
    setSpinner(true);
    const urls = [attachment.url];
    await downloadFiles(urls, false);
    setSpinner(false);
  };

  return (
    <Col lg="4" md="6" sm="12" className="p-1">
      <div className="file-card">
        <Row>
          <Col sm="4">
            <div className="d-flex align-items-center justify-content-center">
              <img src={url} alt="attachment" className="file-card-img" />
            </div>
          </Col>
          <Col sm="5">
            <div className="ms-1 h-100 d-flex align-items-center justify-content-center">
              <div>
                <h5>{getFileNameFromURL(attachment.url)}</h5>
                <small className="secondary-text-color">
                  {bytesToSize(attachment.size)}
                </small>
              </div>
            </div>
          </Col>
          <Col sm="3">
            <div className="me-1 pb-2 h-100 d-flex align-items-end">
              <u
                onClick={handleDownload}
                role="button"
                style={{color:'#527e3c'}}
              >
                Download
              </u>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default AttachmentCard;
