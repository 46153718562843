import React, { useState } from 'react';
import { Modal, Button, closeButton } from 'react-bootstrap';
import './Modal.css';
import CustomDatePicker from '../../common/date-picker/DatePicker';
import { useForm } from 'react-hook-form';
import { generateUniqueId } from 'services/utilities';

const FilterModal = ({ showModal, handleClose, setFilters, statusId }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  const onSubmit = (data) => {
    setFilters(data);
  };

  const ranges = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  return (
    <Modal show={showModal} onHide={handleClose} className="mt-5">
      <Modal.Header className="filter-modal d-flex justify-content-between align-items-center">
        <Modal.Title>
          <h1> Filters</h1>
        </Modal.Title>
        <button onClick={handleClose} className="btn close-btn">
          x
        </button>
      </Modal.Header>
      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className="filter-modal">
          <h4 className="mb-2">Supply Date</h4>
          <div className="date-inputs d-flex">
            <div className="from-date form-group ">
              <label htmlFor="fromDate">From</label>
              <CustomDatePicker
                className={`form-control w-100 ${
                  errors['completion_date'] && 'invalid-input'
                }`}
                name="fromDate"
                control={control}
                setValue={setValue}
              />
            </div>
            <div className="to-date form-group">
              <label htmlFor="toDate">To</label>
              <CustomDatePicker
                className={`form-control w-100 ${
                  errors['completion_date'] && 'invalid-input'
                }`}
                name="toDate"
                control={control}
                setValue={setValue}
              />
            </div>
          </div>

          {/* status filter */}
          <h4 className="mb-1 mt-0">Post Code</h4>
          <div className="location-input form-group">
            <label htmlFor="status">Location/Post Code</label>
            <input
              type=""
              name="zipCode"
              id="zipCode"
              className="form-control"
              placeholder="Enter location or Zip"
              {...register('zipCode')}
            />
          </div>

          <h4 className="mb-2 mt-3">Tender Status</h4>
          <div className=" form-group ">
            <label htmlFor="zipCode">Select Status</label>
            <select
              className="form-input form-control w-100"
              {...register('status')}
            >
              {statusId === 1 && (
                <>
                  <option selected value="Active">
                    Active
                  </option>
                  <option value="Pending">Pending</option>
                  <option value="Closed">Closed</option>
                  <option value="Completed">Completed</option>
                  <option value="Draft">Draft</option>
                  <option value="In Progress">In Progress</option>
                  <option value="dispute">Dispute</option>
                  <option value="">Select All</option>
                </>
              )}
              {statusId === 2 && (
                <>
                  <option value="resolved">Resolved</option>
                  <option value="dispute">Disputed</option>
                  <option value="">Select All</option>
                </>
              )}

              {statusId === 3 && (
                <>
                  <option value="awaiting">Awaiting</option>
                  <option value="approved">Approved</option>
                  <option value="pending">Pending</option>
                  <option value="">Select All</option>
                </>
              )}

              {statusId === 4 && (
                <>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="">Select All</option>
                </>
              )}

              {statusId === 5 && (
                <>
                  <option value="Accepted">Accepted</option>
                  <option value="Pending">Pending</option>
                  <option value="Rejected">Rejected</option>
                  <option value="">Select All</option>
                </>
              )}
            </select>
          </div>

          <h4 className="mb-3 mt-3">Tender Duration (Days)</h4>
          <div className="range">
            <div className="slider-limit d-flex justify-content-between">
              <small>Min</small>
              <small>Max</small>
            </div>
            <input
              type="range"
              name="range"
              min={0}
              max={14}
              defaultValue={0}
              className="form-range range-input w-100"
              {...register('range')}
            />
            <div className="slider-caption d-flex justify-content-between ml-2 mt-1 mb-5 w-100">
              {ranges.map((range) => {
                return <span key={generateUniqueId()}>{range}</span>;
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="filter-modal">
          <div className="modal-action-buttons d-flex justify-content-between w-100">
            <Button
              className="btn btn-white text-dark"
              onClick={() => setFilters({})}
            >
              Clear filters
            </Button>
            <Button className="btn apply-filter-btn" type="submit">
              Apply filters
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default FilterModal;
