import React, { useEffect, useState } from 'react';
import Listings from 'views/listings/Listings';
import ChangePassword from './ChangePassword';
import TwoButtonModal from 'components/Modals/TwoButtonModal';
import { suspendUserApi, changeRoleApi } from 'services/adminService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Rating } from 'react-simple-star-rating';
import SpinnerLoader from 'components/Misc/Spinner';
import { createRoom } from 'services/messagesService';
import { ROLES } from '../../constants/index';
import questionMark from '../../assets/img/icons/Icons-94px-Question-C.svg';

function UserProfile({ user }) {
  const [userId, setUserId] = useState();
  const [showPasswordComponent, setShowPasswordComponent] = useState(false);
  const [showProfile, setShowProfile] = useState(true);
  const [suspendModal, setSuspendModal] = useState(false);
  const [suspendedModal, setSuspendedModal] = useState(false);
  const [roleChange, setRoleChange] = useState(false);
  const [roleChanged, setRoleChnagedModal] = useState(false);
  const history = useHistory();
  const [showSpinner, setSpinner] = useState(false);
  const [isActive, setIsActive] = useState();
  useEffect(() => {
    setUserId(user._id);
  }, [userId]);

  // function to suspend user
  const suspendUser = () => {
    setSpinner(true);
    suspendUserApi(userId)
      .then((data) => {
        setSuspendModal(false);
        setSpinner(false);
        setSuspendedModal(true);
        setIsActive(data?.user?.isActive);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeRole = () => {
    setSpinner(true);
    changeRoleApi(userId)
      .then((data) => {
        setRoleChange(false);
        setSpinner(false);
        setRoleChnagedModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeSuspendModal = () => {
    setSuspendModal(!suspendModal);
  };

  const closeSuspendedModal = () => {
    setSuspendedModal(!suspendedModal);
  };

  const closeRoleChangedModal = () => {
    setRoleChnagedModal(!roleChanged);
  };

  const closeRoleModal = () => {
    setRoleChange(!roleChange);
  };

  const startMessage = async (user_id) => {
    const room = await createRoom({
      ids: [user_id, localStorage.getItem('user_id')],
    });
    history.push('/admin/messages', { room: room._id });
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      {suspendModal && (
        <TwoButtonModal
          icon={
            user?.isActive || isActive
              ? '/dashboard-icons/Suspend.svg'
              : '/dashboard-icons/Success.svg'
          }
          title={user?.isActive || isActive ? 'Suspend User' : 'Activate User'}
          text={`Are you sure you want to ${
            user?.isActive || isActive ? 'Suspend' : 'Activate'
          } this user?`}
          btn1='No'
          btn2='Yes'
          showModal={suspendModal}
          backgroundColor={user.isActive || isActive ? 'red' : '#527e3c'}
          handleClose={closeSuspendModal}
          handleClick1={closeSuspendModal}
          handleClick2={suspendUser}
        />
      )}

      {suspendedModal && (
        <TwoButtonModal
          icon={
            user?.isActive || isActive
              ? '/dashboard-icons/Success.svg'
              : '/dashboard-icons/Suspend.svg'
          }
          title={
            user?.isActive || isActive ? 'User Activated' : 'User Suspended'
          }
          text={`User has successfully been ${
            user?.isActive || isActive ? 'Activated' : 'Suspended'
          }`}
          btn1='View Profile'
          btn2='Go To dashboard'
          showModal={suspendedModal}
          backgroundColor='#527e3c'
          handleClose={closeSuspendedModal}
          handleClick1={closeSuspendedModal}
          handleClick2={() => {
            history.push('dashboard');
          }}
        />
      )}
      {roleChange && (
        <TwoButtonModal
          icon={questionMark}
          title={
            user?.role === ROLES.supplier
              ? 'Change Role to Retailer?'
              : 'Change Role to Supplier?'
          }
          text={
            user?.role === ROLES.supplier
              ? 'Are you sure you want to change the role of supplier to retailer?'
              : 'Are you sure you want to change the role of retailer to supplier?'
          }
          btn1='No'
          btn2='Yes'
          showModal={roleChange}
          backgroundColor='#527e3c'
          handleClose={closeRoleModal}
          handleClick1={closeRoleModal}
          handleClick2={changeRole}
        />
      )}

      {roleChanged && (
        <TwoButtonModal
          icon={'/dashboard-icons/Success.svg'}
          title={'Role Updated'}
          text={'Role has sucessfully been changed'}
          btn1='View Profile'
          btn2='Go To dashboard'
          showModal={roleChanged}
          backgroundColor='#527e3c'
          handleClose={closeRoleChangedModal}
          handleClick1={closeRoleChangedModal}
          handleClick2={() => {
            history.push('dashboard');
          }}
        />
      )}
      {showPasswordComponent ? (
        <ChangePassword user={user} />
      ) : (
        <>
          {showProfile && (
            <div className='profiles-container'>
              <div className='text-white my-4 d-flex justify-content-between align-items-center'>
                <div>
                  User Profile -{' '}
                  <span style={{ opacity: '0.5' }}>{user?.role} profile</span>
                </div>
                <div>
                  {(user?.role === ROLES.supplier ||
                    user?.role === ROLES.retailer) && (
                    <button
                      className='btn update-btn'
                      onClick={() => setRoleChange(true)}
                    >
                      Change role to{' '}
                      {user?.role === ROLES.supplier ? 'retailer' : 'supplier'}
                    </button>
                  )}
                  <button
                    className='btn update-btn mr-5'
                    onClick={() => setShowPasswordComponent(true)}
                  >
                    Update Password
                  </button>
                </div>
              </div>

              <div className='user-profile-info'>
                <div className='user-info px-2 py-2'>
                  <div className='user-information mx-1 my-1'>
                    {user?.profileUrl ? (
                      <img
                        src={user?.profileUrl}
                        className='mr-1 user-info-profile'
                      />
                    ) : (
                      <img
                        src='/Profle-Image.svg'
                        className='mr-1 user-info-profile'
                      />
                    )}{' '}
                    <div className='d-block mx-5'>
                      <p className='title-heading'>{user?.name}</p>
                      <div className='review-star'>
                        {user?.rating?.totalRating ? (
                          <>
                            <div className='me-2'>
                              <Rating
                                size={25}
                                initialValue={user?.rating?.totalRating}
                                fillColor='green'
                                readonly
                              />
                            </div>
                            <div>
                              {parseInt(user?.rating?.totalRating).toFixed(1)}{' '}
                              of {user?.rating?.count} review(s)
                            </div>
                          </>
                        ) : (
                          <>No ratings</>
                        )}
                      </div>
                      <div className='msg-block-btn d-flex mt-3'>
                        <button
                          onClick={() => startMessage(user?._id)}
                          className='msg-btn btn '
                        >
                          Message
                        </button>
                        <button
                          className='block-btn btn'
                          onClick={() => setSuspendModal(true)}
                        >
                          {isActive || user?.isActive ? 'Suspend' : 'UnSuspend'}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='user-stat d-flex justify-content-between align-items-center mt-5 px-2 '>
                    <div className='d-block mx-2'>
                      <h4 className='title-value text-center'>
                        {user?.allTendersExceptClosed || 'N/A'}
                      </h4>
                      <p className='title-text '>Tenders Submitted</p>
                    </div>
                    <div className='d-block  mx-2'>
                      <h4 className='title-value text-center'>
                        {user?.closedTenders || 'N/A'}
                      </h4>
                      <p className='title-text'>Successfull Tenders</p>
                    </div>
                    <div className='d-block mx-2'>
                      <h4 className='title-value text-center'>
                        {user?.closedTenders > 0
                          ? (
                              (user?.closedTenders /
                                user?.allTendersExceptClosed) *
                              100
                            ).toFixed(2) + '%'
                          : 'N/A'}
                      </h4>
                      <p className='title-text'>Tender Success Rate (%)</p>
                    </div>
                  </div>
                </div>
                <div className='profile-info px-2 py-2'>
                  <p className='title-heading border-bottom px-2 py-2'>
                    Profile Info
                  </p>
                  <div className='upper-row px-2 py-2 '>
                    <div>
                      <div>
                        <p className='title-text'>Email</p>
                        <p className='title-value'>{user?.email}</p>
                      </div>
                      <div className='mt-5'>
                        <p className='title-text'>Delivery Address</p>
                        <p className='title-value'>{user?.address || 'N/A'}</p>
                      </div>
                    </div>

                    <div>
                      <div>
                        <p className='title-text'>Phone</p>
                        <p className='title-value'>{user?.phone}</p>
                      </div>
                      <div className='mt-5'>
                        <p className='title-text'>Buisness Name</p>
                        <p className='title-value'>
                          {user?.business_name || 'N/A'}
                        </p>
                      </div>
                    </div>

                    <div>
                      <div>
                        <p className='title-text'>ABN</p>
                        <p className='title-value'>{user?.ABN || 'N/A'}</p>
                      </div>
                      <div className='mt-5'>
                        <p className='title-text'>BSB Number</p>
                        <p className='title-value'>{user?.BSB || 'N/A'}</p>
                      </div>
                    </div>
                  </div>
                  {
                    <div className='mt-1'>
                      <p className='title-text ml-2'>
                        Location : &nbsp;
                        {user?.location && user?.location.length ? (
                          <>
                            {user?.location?.map((locate) => (
                              <>
                                <span className='title-value'>{locate} | </span>
                              </>
                            ))}{' '}
                          </>
                        ) : (
                          <>
                            <span className='title-value'>N/A </span>
                          </>
                        )}
                      </p>
                    </div>
                  }
                </div>
              </div>
            </div>
          )}

          <div className='profile-tenders'>
            <Listings
              inUserProfile={true}
              userId={user._id}
              showProfile={true}
            />
          </div>
        </>
      )}
    </>
  );
}

export default UserProfile;
