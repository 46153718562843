import React, { useEffect, useRef, useState } from 'react';
import './Profile.css';
import { Input } from 'reactstrap';
import { updateProfile } from 'services/authService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getAdminDetails } from 'services/authService';
import { getFilePreview } from 'services/utilities';
import { uploadFiles } from 'services/s3Service';
import SpinnerLoader from 'components/Misc/Spinner';

const Profile = () => {
  const inputRef = useRef(null);
  const history = useHistory();
  const userId = localStorage.getItem('user_id');
  const [user, setUser] = useState({});
  const [profileUrl, setProfileUrl] = useState('');
  const [btnId, setBtnId] = useState(1);
  const [company, setCompany] = useState('');
  const [bsb, setBsb] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [profileUrlChange, setProfileUrlChange] = useState(false);
  const [showSpinner, setSpinner] = useState(true);

  useEffect(() => {
    getAdminDetails(userId).then((data) => {
      setBsb(data?.bank_account?.bsb_number);
      setCompany(data?.business_name);
      setAccountNumber(data?.bank_account?.account_number);
      setUser(data);
      setProfileUrl(data.profileUrl);
      setSpinner(false);
    });
  }, [profileUrl]);

  const changePhoto = (e) => {
    inputRef.current.click();
  };

  const removePhoto = (e) => {
    setSpinner(true);
    updateProfile({ profileUrl: null })
      .then(() => {
        setSpinner(false);
        setProfileUrl(null);
        setProfileUrlChange(null);
        localStorage.setItem('profileUrl', '');
      })
      .catch((error) => {
        setSpinner(false);
        console.log(error);
      });
  };

  const handleFileChange = async (event) => {
    setSpinner(true);
    let files = [];
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    const file = await getFilePreview(fileObj);
    files.push(file);
    let url = await uploadFiles(files, 'profile_images/');
    const updatedUrl = url[0]?.url;
    setProfileUrlChange(updatedUrl);
    updateProfile({
      profileUrl: updatedUrl,
    })
      .then(() => {
        setSpinner(false);
        localStorage.setItem('profileUrl', updatedUrl);
      })
      .catch((error) => {
        setSpinner(false);
        console.log(error);
      });
  };

  const handleUpdate = () => {
    setSpinner(true);
    updateProfile({
      business_name: company,
      bank_account: {
        account_number: accountNumber,
        bsb_number: bsb,
      },
    })
      .then(() => {
        setSpinner(false);
        history.push('/admin/profile');
        setBtnId(1);
      })
      .catch((error) => {
        setSpinner(false);
        console.log(error);
      });
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <div>
        <div className="setting-container">
          <span className="text-white">Profile</span>

          <div className="setting-block my-4">
            {btnId === 1 && (
              <>
                <div className="d-flex justify-content-between align-items-center mx-2 my-3 border-bottom">
                  <div className="p-2 mx-2 my-2 profile-text">Profile Info</div>
                  <div role="button" onClick={() => setBtnId(2)}>
                    <img src="/dashboard-icons/Edit.svg" alt=" edit" />
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center w-75">
                  <div>
                    <img
                      src={
                        profileUrlChange || profileUrl || '/Profle-Image.svg'
                      }
                      alt="Profile Image"
                      className="admin-pic ml-5 my-5"
                    />
                  </div>
                  <div>
                    <div className="grey-text mb-1">Email</div>
                    <div className="normal-text">{user?.email}</div>
                  </div>
                  <div>
                    <div className="grey-text mb-1">Company Name</div>
                    <div className="normal-text">{company || 'N/A'}</div>
                  </div>
                  <div>
                    <div className="grey-text mb-1">BSB Number</div>
                    <div className="normal-text">{bsb || 'N/A'}</div>
                  </div>
                  <div>
                    <div className="grey-text mb-1">Account Number</div>
                    <div className="normal-text">{accountNumber || 'N/A'}</div>
                  </div>
                </div>
              </>
            )}

            {btnId === 2 && (
              <>
                <div className="d-flex justify-content-between align-items-center mx-2 my-3 border-bottom">
                  <div className="p-2 mx-2 my-2 profile-text">Edit Profile</div>
                </div>
                <div className="d-flex align-items-center mx-3">
                  <div>
                    <img
                      src={
                        profileUrlChange || profileUrl || '/Profle-Image.svg'
                      }
                      alt=""
                      className="admin-pic"
                    />
                  </div>
                  <div>
                    <div className="mx-3 my-1">
                      <input
                        style={{ display: 'none' }}
                        ref={inputRef}
                        type="file"
                        onChange={handleFileChange}
                      />
                      <button className="btn success" onClick={changePhoto}>
                        Change Photo
                      </button>
                      <button className="btn danger" onClick={removePhoto}>
                        Remove Photo
                      </button>
                    </div>
                    <div className="grey-text mx-3">
                      Maximum size of 1MB. JPG, GIF, or PNG.
                    </div>
                  </div>
                </div>
                <div className="w-25 mx-4 my-4">
                  <div className="my-3 ">
                    <label htmlFor="" className="normal-text">
                      Company Name
                    </label>
                    <Input
                      className="d-inline-block listing-search-box"
                      placeholder="Enter company name"
                      type="text"
                      onChange={(e) => setCompany(e.target.value)}
                      value={company}
                    />
                  </div>
                  <div className="my-3 ">
                    <label htmlFor="" className="normal-text">
                      BSB Number
                    </label>
                    <Input
                      className="d-inline-block listing-search-box"
                      placeholder="Enter BSB number"
                      type="text"
                      onChange={(e) => setBsb(e.target.value)}
                      value={bsb}
                    />
                  </div>
                  <div className="my-3">
                    <label htmlFor="" className="normal-text">
                      Account Number
                    </label>
                    <Input
                      className="d-inline-block listing-search-box"
                      placeholder="Enter account number"
                      type="text"
                      onChange={(e) => setAccountNumber(e.target.value)}
                      value={accountNumber}
                    />
                  </div>
                  <div className="my-3 action-btn">
                    <button
                      className="btn light mb-3"
                      onClick={() => history.push('/admin/dashboard')}
                    >
                      Cancel
                    </button>
                    <button className="btn success mb-3" onClick={handleUpdate}>
                      Update
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>{' '}
    </>
  );
};

export default Profile;
