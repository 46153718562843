import React, { useState, useEffect } from 'react';
import './quoteInvoice.css';
import { useLocation, useParams } from 'react-router-dom';
import { getInvoiceDetail } from 'services/adminService';
import TwoButtonModal from 'components/Modals/TwoButtonModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setTransactionAmountApi } from 'services/adminService';

const UploadInvoice = () => {
  const { id } = useParams();
  const history = useHistory();
  const [invoice, setInvoice] = useState();
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [totalTransactionAmount, setTotalTransactionAmount] = useState();

  useEffect(() => {
    getInvoiceDetail(id).then((data) => {
      setInvoice(data);
    });
  }, []);

  const uploadInvoice = () => {
    if(totalTransactionAmount){
      setTransactionAmountApi(id, { transactionAmount: totalTransactionAmount })
      .then((data) => {
        setInvoiceModal(true);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  };

  return (
    <>
    <div className='invoice-container-heading'><span style={{opacity:'0.5'}}>List of Transactions - </span>Upload Invoice</div>
      {invoiceModal && (
        <TwoButtonModal
          icon={'/dashboard-icons/Success.svg'}
          title={'Invoice Uploaded?'}
          text={
            'Your Invoice has successfully been uploaded. Supplier will be notified about this.'
          }
          btn2={'View Invoice'}
          handleClick2={() => {
            history.push(`/admin/transaction/${invoice._id}`);
          }}
          handleClose={() => setInvoiceModal(false)}
          showModal={invoiceModal}
          backgroundColor={'#527e3c'}
        />
      )}
      <div className="upload-invoice-container ">
        <div className="">
          <div className="d-flex justify-content-between align-items-center">
            <div className="upload-invoice-text">Upload Invoice</div>
            <div className="d-flex">
              <div>#{invoice?.invoice?._id} &nbsp;</div>
              <div>{invoice?.tender_title[0]}</div>
            </div>
          </div>
          <div className="horizontal-line my-3"></div>
          <div className="heading-text">
            Please Transfer Amount to the following Account
          </div>
          <div className="d-flex my-3">
            <div>
              <div className="heading-text">Account Name</div>
              <div className="quote-title-text">
                {invoice?.supplier[0]?.bank_account?.account_name}
              </div>
            </div>
            <div className="mx-5">
              <div className="heading-text">BSB Number</div>
              <div className="quote-title-text">
                {invoice?.supplier[0]?.bank_account?.bsb_number}
              </div>
            </div>
            <div>
              <div className="heading-text">Account Number</div>
              <div className="quote-title-text">
                {invoice?.supplier[0]?.bank_account?.account_number}
              </div>
            </div>
          </div>

          <div>
            <label htmlFor="" className="quote-title-text">
              Total Transaction Amount
            </label>
            <input
              type="number"
              className="form-control w-25"
              defaultValue={totalTransactionAmount}
              onChange={(e) => setTotalTransactionAmount(e.target.value)}
              placeholder="Enter Amount"
            />
          </div>
          <div>
            <button className="btn upload-btn my-3" onClick={uploadInvoice}>
              Upload Invoice
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadInvoice;
