import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import { getUploadedFilesPreview } from 'services/utilities';
import { useState, useEffect } from 'react';
import Dropzone from '../../common/drop-zone/DropZone';
import './UpdateTender.css';
import CustomDatePicker from '../../common/date-picker/DatePicker';
import { getCall } from '../../services/APIsService';
import { authorizedPutCall } from '../../services/APIsService';
import { getOneTender } from '../../services/adminService';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import cashIcon from '../../assets/img/icons/Cash.svg';
import creditIcon from '../../assets/img/icons/Credit.svg';
import SpinnerLoader from 'components/Misc/Spinner';
import { uploadFiles } from 'services/s3Service';
import OneButtonModal from 'components/Modals/one-button-modal/OneButtonModal';
import toast, { Toaster } from 'react-hot-toast';
import { errorToast, successToast } from 'shared/constants';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  DRAFT_DONE_MODAL_MSG,
  DRAFT_DONE_MODAL_TITLE,
  DRAFT_MODAL_MSG,
  DRAFT_MODAL_TITLE,
  FARM_DISTANCE_ENUM,
  IMAGES_ASSESTS_PATH,
  PAYMENT_MODE,
  PAYMENT_OPTIONS,
  PICKUP_RADIUS_ENUM,
  TENDER_STATUSES,
} from '../../constants';

function UpdateTender() {
  const { id } = useParams();
  const [options, setOptions] = useState([]);
  const [tender, setTender] = useState(null);
  const [files, setFiles] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [payDirect, setPayDirect] = useState(true);
  const [showSpinner, setSpinner] = useState(false);
  const [resolvedModal, setResolvedModal] = useState(false);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
    getValues,
  } = useForm();

  const getTenderCategories = async () => {
    try {
      const response = await getCall('/tender-category');
      if (response.error) {
        toast.error('something went wrong', errorToast);
      } else {
        setOptions(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('something went wrong', errorToast);
    } finally {
      setSpinner(false);
    }
  };

  const getTender = async () => {
    try {
      setSpinner(true);
      const response = await getOneTender(id);
      if (response.error) {
        toast.error('something went wrong', errorToast);
      } else {
        setTender(response);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('something went wrong', errorToast);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getTenderCategories();
    getTender();
  }, []);

  useEffect(() => {
    if (tender) {
      setValue('title', tender.title || '');
      setValue(
        'duration',
        tender.duration
          ? [
              tender.duration[0] ? new Date(tender.duration[0]) : new Date(),
              tender.duration[1] ? new Date(tender.duration[1]) : '',
            ]
          : [new Date(), '']
      );
      setValue(
        'completion_date',
        tender.completion_date ? new Date(tender.completion_date) : ''
      );
      const matchingCategory = options.find(
        (category) => category._id === tender.tender_category
      );
      if (matchingCategory) {
        setSelectedOption(matchingCategory);
      }
      setValue('preferred_suppliers', tender.preferred_suppliers || '');
      setValue('details', tender.details || '');
      setValue('transport', tender.transport);
      setValue(
        'farm_distance',
        tender.farm_distance || FARM_DISTANCE_ENUM.RANGE_0_5KM
      );
      setValue(
        'pickup_radius',
        tender.pickup_radius || PICKUP_RADIUS_ENUM.RANGE_0_50KM
      );
      setValue('extra_info', tender.extra_info || '');
      setValue('payment_option', tender.payment_option);
      setValue('payment_mode', tender.payment_mode);
      setValue('acknowledge', tender.acknowledge);
      setValue('post_code', tender.post_code);
      setFiles(
        tender.attachments ? getUploadedFilesPreview(tender.attachments) : []
      );
    }
  }, [tender, options]);

  const handleSelectChange = (e) => {
    const selected = e.target.value;
    const selectedObject = options.find((option) => option.title === selected);
    setSelectedOption(selectedObject);
  };

  const handleClose = () => {
    setResolvedModal(false);
  };

  const onSubmit = async (data) => {
    setSpinner(true);
    try {
      let urls = await uploadFiles(files, 'tenders/');
      urls.splice(0, tender.attachments.length);
      let newUrls = [...tender.attachments, ...urls];
      let newData = { ...data };
      newData.attachments = newUrls;
      newData.tender_category = selectedOption._id;
      const response = await authorizedPutCall(
        `/tender/tender-update/${id}`,
        newData
      );
      if (response.error) {
        toast.error('Something Went Wrong', errorToast);
      } else {
        setResolvedModal(true);
      }
    } catch (error) {
      toast.error('something went wrong', errorToast);
    } finally {
      setSpinner(false);
    }
  };

  const filesChange = async (newFile) => {
    setFiles(newFile);
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <div className='route-text'>
        <span style={{ color: 'rgb(209, 204, 204)' }}>Listings</span> - Edit
        Details
      </div>
      <div className='mt-5 listing-container-update-page form-box footer-space'>
        <p>Edit Tender</p>
        <hr />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className='mb-5 pt-4'>
            <Col sm='12'>
              <h2>Tender Details</h2>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col sm='12' md='6'>
              <div className='d-flex align-items-center justify-content-between'>
                <label className='mb-2 d-block'>
                  Tender Title <span className='required-color'>*</span>
                </label>
              </div>
              <input
                placeholder='Enter your title'
                className={`form-input w-100 ${
                  errors['title'] && 'invalid-input'
                }`}
                type='text'
                {...register('title', { required: true })}
              />
            </Col>
            <Col sm='12' md='6'>
              <div className='d-flex align-items-center justify-content-between'>
                <label className='mb-2 d-block'>
                  Delivery/Pickup Date <span className='required-color'>*</span>
                </label>
              </div>
              <CustomDatePicker
                className={`form-input w-100 ${
                  errors['completion_date'] && 'invalid-input'
                }`}
                name='completion_date'
                placeholder={'Select date'}
                control={control}
                setValue={setValue}
                required={true}
              />
            </Col>
          </Row>

          <Row className='mb-4'>
            {/* DropDown  */}
            <Col sm='12' md='6'>
              <div className='d-flex align-items-center justify-content-between'>
                <label className='mb-2 d-block'>
                  Tender Category <span className='required-color'>*</span>
                </label>
              </div>
              <select
                className='form-input w-100'
                style={{ cursor: 'pointer' }}
                value={selectedOption?.title}
                onChange={handleSelectChange}
                required
              >
                <option value=''>Select an option</option>
                {options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.title}
                  </option>
                ))}
              </select>
            </Col>
            <Col sm='12' md='6'>
              <div className='d-flex align-items-center justify-content-between'>
                <label className='mb-2 d-block'>
                  Tender Duration <span className='required-color'>*</span>
                </label>
              </div>
              <CustomDatePicker
                className={`form-input w-100 ${
                  errors['duration'] && 'invalid-input'
                }`}
                name='duration'
                placeholder={'Select date'}
                control={control}
                setValue={setValue}
                range={true}
                required={true}
                duration={selectedOption?.duration || 0}
              />
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col sm='12'>
              <div className='d-flex align-items-center justify-content-between'>
                <div>
                  <label className='mb-2 d-inline-block'>
                    Order/Product Details
                  </label>
                </div>
                <small className='secondary-text-color'>(Optional)</small>
              </div>
              <textarea
                placeholder='Enter details'
                className={`form-input w-100 ${
                  errors['details'] && 'invalid-input'
                }`}
                rows={'5'}
                {...register('details')}
              />
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col sm='12'>
              <div className='d-flex align-items-center justify-content-between'>
                <div>
                  <label className='mb-2 d-inline-block'>
                    List any preferred supplier(s){' '}
                    <span className='required-color'>*</span>
                  </label>
                </div>
              </div>
              <textarea
                placeholder='Enter preferred supplier(s)'
                className={`form-input w-100 ${
                  errors['preferred_suppliers'] && 'invalid-input'
                }`}
                rows={'5'}
                {...register('preferred_suppliers', { required: true })}
              />
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col sm='12'>
              <div className='d-flex align-items-center justify-content-between'>
                <div>
                  <label className='mb-2 d-inline-block'>
                    Attach Files (Product Lists)
                  </label>
                </div>
                <small className='secondary-text-color'>(Optional)</small>
              </div>
              <Dropzone onChange={filesChange} value={files} />
            </Col>
          </Row>

          <h1>Shipping Details</h1>

          <Row className='mb-4'>
            <Col sm='12' lg='6'>
              <label className='mb-2 d-block'>
                Town closest to Farm
                <span className='required-color'>*</span>
              </label>
              <Controller
                control={control}
                rules={{ required: true }}
                name='post_code'
                render={({ field: { onChange } }) => (
                  <ReactGoogleAutocomplete
                    placeholder='Enter Address'
                    className={`form-input w-100 ${
                      errors['post_code'] && 'invalid-input'
                    }`}
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    onPlaceSelected={(place) => {
                      onChange(place.formatted_address);
                    }}
                    defaultValue={tender?.post_code}
                    options={{
                      types: ['geocode', 'establishment'],
                      componentRestrictions: { country: 'aus' },
                    }}
                  />
                )}
              />
            </Col>
            <Col sm='12' lg='6'>
              <label className='mb-2 d-block ml-2'>
                Transport Option <span className='required-color'>*</span>
              </label>
              <label className='mt-2'>
                <input
                  className='mx-2 primary-checkbox'
                  type='radio'
                  value='Delivery'
                  checked={watch('transport') === 'Delivery'}
                  {...register('transport', { required: true })}
                />
                Deliver to Farm
              </label>

              <label className='px-3'>
                <input
                  className='mx-2 primary-checkbox'
                  type='radio'
                  value='Pickup'
                  checked={watch('transport') === 'Pickup'}
                  {...register('transport', { required: true })}
                />
                Pickup
              </label>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col className='' sm='12' lg='6'>
              <label
                className='mb-2 d-block'
                style={{
                  color: watch('transport') === 'Pickup' ? 'grey' : 'black',
                }}
              >
                Farm Distance from Town Center
                <span className='required-color'>*</span>
              </label>

              <select
                className='form-input form-select w-100'
                aria-label='Default select example'
                placeholder='Select Distance'
                {...register('farm_distance')}
                disabled={watch('transport') === 'Pickup'}
                defaultValue={'farm_distance'}
              >
                <option disabled className='fw-bold'>
                  Enter Distance
                </option>
                {Object.values(FARM_DISTANCE_ENUM).map((distance) => {
                  return (
                    <option value={distance} className='optional-text'>
                      {distance}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col className='' sm='12' lg='6'>
              <label
                className='mb-2 d-block'
                style={{
                  color: watch('transport') === 'Delivery' ? 'grey' : 'black',
                }}
              >
                Pickup Radius from Town Centre
                <span className='required-color'>*</span>
              </label>
              <select
                className='form-input form-select w-100'
                aria-label='Default select example'
                {...register('pickup_radius')}
                disabled={watch('transport') === 'Delivery'}
                defaultValue={PICKUP_RADIUS_ENUM.RANGE_0_50KM}
              >
                <option disabled className='fw-bold'>
                  Enter Radius
                </option>
                {Object.values(PICKUP_RADIUS_ENUM).map((distance) => {
                  return (
                    <option value={distance} className='optional-text'>
                      {distance}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col sm='12'>
              <div className='d-flex align-items-center justify-content-between'>
                <label className='mb-2 d-block'>
                  Extra Shipping Instructions
                </label>
                <small className='secondary-text-color'>(Optional)</small>
              </div>
              <textarea
                placeholder='Enter details'
                className={`form-input w-100 ${
                  errors['extra_info'] && 'invalid-input'
                }`}
                rows={'5'}
                {...register('extra_info')}
              />
            </Col>
          </Row>

          <h1>Payment Details</h1>

          <Row className='mb-4'>
            <label className='mb-2 ml-3 d-block'>
              Payment Option <span className='required-color'>*</span>
            </label>
            <Col sm='12'>
              <label>
                <input
                  className='mx-2 primary-checkbox'
                  type='radio'
                  value={PAYMENT_OPTIONS.DIRECT}
                  {...register('payment_option', { required: true })}
                  role='button'
                  onClick={() => setPayDirect(true)}
                />
                Pay Supplier Direct
              </label>

              <label className='px-3'>
                <input
                  className='mx-2 primary-checkbox'
                  type='radio'
                  value={PAYMENT_OPTIONS.THROUGH_AG}
                  {...register('payment_option', { required: true })}
                  role='button'
                  onClick={() => setPayDirect(false)}
                />
                Pay through iinputs.com (no extra cost)
              </label>
            </Col>
          </Row>

          <Row className='mb-4'>
            <div className='payment-option mx-1'>
              <label
                htmlFor='cashRadio'
                className='w-100 d-flex align-items-center justify-content-between'
              >
                <div className='d-flex align-items-center'>
                  <img src={cashIcon} alt='cash' />
                  <div className='fw-bold'>Cash</div>
                </div>
                <div>
                  <input
                    id='cashRadio'
                    className='mx-2 primary-checkbox'
                    type='radio'
                    value={PAYMENT_MODE.CASH}
                    {...register('payment_mode', { required: true })}
                  />
                </div>
              </label>
            </div>

            {payDirect && (
              <div className='payment-option mx-1'>
                <label
                  htmlFor='creditRadio'
                  className='w-100 d-flex align-items-center justify-content-between'
                >
                  <div className='d-flex align-items-center'>
                    <img src={creditIcon} alt='credit' />
                    <div>
                      <div className='fw-bold'>Credit</div>
                      <div>Subject to Supplier terms and conditions</div>
                    </div>
                  </div>
                  <div>
                    <input
                      id='creditRadio'
                      className='mx-2 primary-checkbox'
                      type='radio'
                      value={PAYMENT_MODE.CREDIT}
                      {...register('payment_mode', { required: true })}
                    />
                  </div>
                </label>
              </div>
            )}
          </Row>

          {payDirect && watch('payment_mode') === PAYMENT_MODE.CREDIT && (
            <div className='mb-4 d-flex align-items-center'>
              <input
                className='mx-2 primary-checkbox align-self-start mt-1'
                type='checkbox'
                value=''
                {...register('acknowledge', { required: true })}
                defaultChecked={true}
                required={true}
              />
              <label className='acknowledge-text'>
                I acknowledge that credit will be approved at the Suppliers
                discretion and this tender maybe cancelled in the event of an
                unsuccessful credit application.
              </label>
            </div>
          )}

          <Row className='mb-4 d-flex justify-content-end'>
            <button
              className='btn cancel-btn'
              onClick={() => {
                history.push('/admin/listings');
              }}
            >
              cancel
            </button>
            <button type='submit' className='btn success-btn next-btn'>
              Update Changes
            </button>
          </Row>
        </form>
      </div>
      <OneButtonModal
        show={resolvedModal}
        btn={'close'}
        title={'Tender Updated'}
        message={'Tender has been updated successfully.'}
        click={handleClose}
      />
      <Toaster />
    </>
  );
}

export default UpdateTender;
