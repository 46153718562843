import Login from 'views/auth/Login.js';
import ForgotPassword from 'views/auth/ForgotPassword';
import ResetPassword from 'views/auth/ResetPassword';
import Dashboard from 'views/dashboard/Dashboard';
import Disputes from 'views/disputes/Disputes';
import Dispute from 'views/disputes/Dispute';
import Listings from 'views/listings/Listings';
import Listing from 'views/listings/Listing';
import Transactions from 'views/transactions/Transactions';
import Transaction from 'views/transactions/Transaction';
import UserProfiles from 'views/user-profiles/UserProfiles';
import UserProfile from 'views/user-profiles/UserProfile';
import CheckEmail from 'views/auth/CheckEmail';
import QuoteInvoice from 'views/transactions/quote-invoice/QuoteInvoice';
import UploadInvoice from 'views/transactions/quote-invoice/UploadInvoice';
import Messages from 'views/messages/Messages';
import Notifications from 'views/notifications/Notifications';
import Setting from 'views/settings/Setting';
import Profile from 'views/profile/Profile';
import UpdateTender from 'views/update-tender/UpdateTender';
import UpdateQuote from 'views/update-quote/UpdateQuote';
import UpdateUser from 'views/update-user/UpdateUser';

export const routes = [
  {
    path: '/login',
    name: 'Login',
    miniName: 'L',
    component: Login,
    layout: '/user',
  },
  {
    path: '/forgot_password',
    name: 'Forgot Password',
    miniName: 'FP',
    component: ForgotPassword,
    layout: '/user',
  },
  {
    path: '/reset_password/:id',
    name: 'Reset Password',
    miniName: 'RP',
    component: ResetPassword,
    layout: '/user',
  },
  {
    path: '/check_email',
    name: 'Check Email',
    miniName: 'CE',
    component: CheckEmail,
    layout: '/user',
  },
];

export const sidebarRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard ',
    icon: '/Dashboard',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/listings',
    name: 'Listings ',
    icon: '/Listings',
    component: Listings,
    layout: '/admin',
  },
  {
    path: '/disputes',
    name: 'Disputes',
    icon: '/Dispute',
    component: Disputes,
    layout: '/admin',
  },
  {
    path: '/transactions',
    name: 'Transactions',
    icon: '/Transactions',
    component: Transactions,
    layout: '/admin',
  },
  {
    path: '/user-profiles',
    name: 'User Profiles',
    icon: '/Profiles',
    component: UserProfiles,
    layout: '/admin',
  },
  {
    path: '/listing/:id',
    component: Listing,
    layout: '/admin',
  },
  {
    path: '/transaction/:id',
    component: QuoteInvoice,
    layout: '/admin',
  },
  {
    path: '/upload-invoice/:id',
    component: UploadInvoice,
    layout: '/admin',
  },
  {
    path: '/messages',
    component: Messages,
    layout: '/admin',
  },
  {
    path: '/notifications',
    component: Notifications,
    layout: '/admin',
  },
  {
    path: '/setting',
    component: Setting,
    layout: '/admin',
  },
  {
    path: '/profile',
    component: Profile,
    layout: '/admin',
  },
  {
    path: '/tender-update/:id',
    component: UpdateTender,
    layout: '/admin',
  },
  {
    path: '/quote-update/:id',
    component: UpdateQuote,
    layout: '/admin',
  },
  {
    path: '/user-update/:id',
    component: UpdateUser,
    layout: '/admin',
  },
];
