import React from 'react';
import './Modal.css';
import { Modal, Button, closeButton } from 'react-bootstrap';

const DisputeReasonModal = ({
  title,
  text,
  handleClose,
  showModal,
}) => {
  return (
    <Modal show={showModal} onHide={handleClose} className="mt-5">
      <Modal.Header className="d-flex justify-content-between align-items-center">
        <Modal.Title>
            <div className='reason-title'>{title}</div>
        </Modal.Title>
      </Modal.Header>
      <div className='border border-bottom'></div>
      <Modal.Body className="">
        <div className='reason-text'>
            {text}
        </div>
        <button className='btn reason-close-btn' onClick={handleClose}>Close</button>
    
      </Modal.Body>
    </Modal>
  );
};

export default DisputeReasonModal;
