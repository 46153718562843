import React, { useState } from 'react';
import './Setting.css';
import { Input } from 'reactstrap';
import { toast } from 'react-hot-toast';
import { changePassword } from 'services/authService';
import OneButtonModal from 'components/Modals/one-button-modal/OneButtonModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SpinnerLoader from 'components/Misc/Spinner';
import { checkPassSpecialChars } from 'shared/constants';
import { checkPassChars } from 'shared/constants';
import { checkPassCase } from 'shared/constants';
import { passwordRegex } from 'shared/constants';

const Setting = () => {
  const history = useHistory();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('  ');
  const [showModal, setShowModal] = useState(false);
  const [showSpinner, setSpinner] = useState(false);

  const validatePassword = (value) => {
    return passwordRegex.test(value);
  };

  const handleSubmit = () => {
    if (!oldPassword || !newPassword || !confirmNewPassword) {
      toast.error('All feilds required');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      toast.error('All new password and confirm password must be same');
      return;
    }

    if (!validatePassword(newPassword)) {
      toast.error(
        'Password must contain 8 characters, one special character, one uppercase and one lowercase'
      );
      return;
    }

    setSpinner(true);
    changePassword(oldPassword, newPassword, confirmNewPassword)
      .then((data) => {
        if (data.message) {
          setSpinner(false);
          setShowModal(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      {showModal && (
        <OneButtonModal
          show={showModal}
          handleClose={handleClose}
          click={() => history.push('/admin/dashboard')}
          title={'Password Changed'}
          message={'Password has successfully been updated'}
          btn={'Go to Dashboard'}
          successBtn={true}
        />
      )}
      <div>
        <div className="setting-container">
          <span className="text-white">Setting</span>

          <div className="setting-block my-4">
            <div className="border-bottom p-2 mx-2 heading-text bold">
              Change Password
            </div>
            <div className="d-flex flex-column p-2 mx-2 w-25">
              <div className="my-3 ">
                <label htmlFor="oldPassword" className="normal-text-black">
                  Current Password
                </label>
                <Input
                  className="d-inline-block listing-search-box"
                  placeholder="Enter old password"
                  type="password"
                  name="oldPassword"
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
              <div className="my-3 ">
                <label htmlFor="" className="normal-text-black">
                  New Password
                </label>
                <Input
                  className="d-inline-block listing-search-box"
                  placeholder="Enter new password"
                  type="password"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="my-3 ">
                <label htmlFor="" className="normal-text-black">
                  Confirm New Password
                </label>
                <Input
                  className="d-inline-block listing-search-box"
                  placeholder="Enter password again"
                  type="password"
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
              </div>
              <div>
                <img
                  src={
                    checkPassChars(newPassword, confirmNewPassword)
                      ? '/dashboard-icons/tick-green.svg'
                      : '/dashboard-icons/Tick.svg'
                  }
                  alt=""
                />
                <span className="password-text">Must be 8 Characters</span>
              </div>
              <div>
                <img
                  src={
                    checkPassSpecialChars(newPassword, confirmNewPassword)
                      ? '/dashboard-icons/tick-green.svg'
                      : '/dashboard-icons/Tick.svg'
                  }
                  alt=""
                />
                <span className="password-text">One special character</span>
              </div>
              <div>
                <img
                  src={
                    checkPassCase(newPassword, confirmNewPassword)
                      ? '/dashboard-icons/tick-green.svg'
                      : '/dashboard-icons/Tick.svg'
                  }
                  alt=""
                />
                <span className="password-text">Atleast One uppercase</span>
              </div>
              <div>
                <button
                  className="btn change-btn my-4 mx-2"
                  onClick={handleSubmit}
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
