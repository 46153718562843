import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './DropZone.css';
import {
  generateUniqueId,
  getFilePreview,
  getSubString,
} from '../../services/utilities';

function Dropzone({ onChange, value }) {
  useEffect(() => {
    // Update the state when the value prop changes
    setFiles(value ? value : []);
  }, [value]);

  const [files, setFiles] = useState(value ? value : []);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', 'jpeg'],
      'application/*': ['.pdf', '.xlsx', '.xls'],
    },
    multiple: true,
    maxFiles: 10,
    maxSize: 20971520, //20 mb
    onDrop: async (acceptedFiles) => {
      const newFiles = await Promise.all(
        acceptedFiles.map((file) => getFilePreview(file))
      );
      onChange([...files, ...newFiles]);
      setFiles((prev) => [...prev, ...newFiles]);
    },
  });

  const thumbs = files.map((file) => (
    <div key={generateUniqueId()}>
      <div className={'thumb'}>
        <div className={'thumbInner'}>
          <img src={file.preview} className='thumb-img' alt='thumb' />
        </div>
      </div>
    </div>
  ));

  return (
    <section className='dropzone-container'>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <div>
          <p className='secondary-text-color text-center'>
            Drop your files here, or <u>browse</u>
          </p>
          <p className='secondary-text-color text-center'>
            PNG, JPEG, PDF, XLXS, XLS, Max items at once: 10, Max size: 20MB
          </p>
        </div>
      </div>
      <aside className={'thumbsContainer'}>{thumbs}</aside>
    </section>
  );
}

export default Dropzone;
