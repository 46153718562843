import './UpdateUser.css';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { ROLES } from '../../constants';
import 'react-phone-number-input/style.css';
import { getUploadedFilesPreview, getFilePreview } from 'services/utilities';
import { useState, useEffect, useRef } from 'react';
import Dropzone from '../../common/drop-zone/DropZone';
import './UpdateUser.css';
import { authorizedPutCall } from '../../services/APIsService';
import { getOneUser } from '../../services/adminService';
import SpinnerLoader from 'components/Misc/Spinner';
import { uploadFiles } from 'services/s3Service';
import OneButtonModal from 'components/Modals/one-button-modal/OneButtonModal';
import userProfile from '../../assets/img/icons/User-Profile.svg';
import toast, { Toaster } from 'react-hot-toast';
import { errorToast, successToast } from '../../shared/constants';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { TENDER_DETAIL_TABS, TRANSPORT } from '../../constants';
import ReactGoogleAutocomplete from 'react-google-autocomplete';

function UpdateUser() {
  const { id } = useParams();
  const inputRef = useRef(null);
  const [user, setUser] = useState(null);
  const [showSpinner, setSpinner] = useState(false);

  const [profileUrlChange, setProfileUrlChange] = useState(false);
  const [profile, setProfile] = useState({});

  const [profileUrl, setProfileUrl] = useState();
  const [fileName, setFileName] = useState();

  const [resolvedModal, setResolvedModal] = useState(false);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
    getValues,
  } = useForm();

  const getProfile = async () => {
    try {
      setSpinner(true);
      const response = await getOneUser(id);
      if (response.error) {
        toast.error('something went wrong', errorToast);
      } else {
        setUser(response);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('something went wrong', errorToast);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (user) {
      setValue('phone', user.phone);
      setValue('name', user.name);
      setValue('role', user.role);
      setValue('business_name', user.business_name || '');
      setValue('email', user.email || '');
      setValue('ABN', user.ABN || '');
      setValue('address', user.address || '');
      setValue('profileUrl', user.profileUrl);
      setProfileUrl(user.profileUrl);
    }
  }, [user]);

  const changePhoto = (e) => {
    inputRef.current.click();
  };

  const removePhoto = (e) => {
    setProfileUrl(null);
    setValue('profileUrl', null);
  };

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    const file = await getFilePreview(fileObj);
    setProfileUrl(file);
    setFileName(fileObj?.name);
    setProfileUrlChange(true);
    setProfile((prevProfile) => ({
      ...prevProfile,
      profileUrl: file.preview,
    }));
  };

  const handleClose = () => {
    setResolvedModal(false);
  };

  const onSubmit = async (data) => {
    setSpinner(true);
    try {
      let url;
      let formData;
      data.phone = data.phone ? data.phone : '';
      if (profileUrlChange) {
        url = await uploadFiles([profileUrl], 'profile_images/');
        formData = {
          ...data,
          profileUrl: url[0]?.url || profile?.profileUrl,
        };
      } else {
        formData = { ...data };
      }
      const response = await authorizedPutCall(
        `/user/update_user_admin_call/${id}`,
        formData
      );
      if (response.error) {
        toast.error('something went wrong', errorToast);
      } else {
        setResolvedModal(true);
      }
    } catch (error) {
      toast.error('something went wrong', errorToast);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <div className='route-text'>Profile</div>
      <div className='mt-5 listing-container-update-page form-box footer-space'>
        <p>Edit Profile</p>
        <hr />

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='edit-flex-start'>
            <div className='mr-4'>
              {profileUrl ? (
                <img
                  src={profileUrl.preview ? profileUrl.preview : profileUrl}
                  alt=''
                  className='user-image'
                />
              ) : (
                <img src={userProfile} alt='' className='user-image' />
              )}
            </div>
            <div>
              <div className='edit-flex-start my-2'>
                <input
                  style={{ display: 'none' }}
                  ref={inputRef}
                  type='file'
                  onChange={handleFileChange}
                />
                <button
                  className='change-btn btn mx-2 my-1'
                  onClick={changePhoto}
                  type='button'
                >
                  {profileUrl ? 'Change Photo' : 'Upload Photo'}
                </button>
                {profileUrl && (
                  <button
                    className='remove-btn btn mx-2  my-1'
                    onClick={removePhoto}
                    type='button'
                  >
                    Remove Photo
                  </button>
                )}
                {fileName && profileUrl && <small>{fileName}</small>}
              </div>

              <div className='edit-title mx-2'>
                Maximum size of 1MB, JPG, GIF, or PNG.
              </div>
            </div>
          </div>

          <Row className='my-4'>
            <Col sm='12' md='6'>
              <label className='mb-2 d-block'>Contact Name</label>
              <input
                placeholder='Enter your name'
                className={`form-input w-100 ${
                  errors['name'] && 'invalid-input'
                }
                  }`}
                // required
                type='text'
                defaultValue={user?.name}
                {...register('name', { required: true })}
              />
            </Col>
            <Col sm='12' md='6'>
              <label className='mb-2 d-block'>Email</label>
              <input
                placeholder='Enter your email'
                className={`form-input w-100 ${
                  errors['email'] && 'invalid-input'
                }
                  }`}
                type='text'
                defaultValue={user?.email}
                {...register('email', { required: true })}
              />
            </Col>
          </Row>

          <Row className='mb-4 '>
            <Col sm='12' md='6'>
              <label className='mb-2 d-block'>Phone</label>
              <Controller
                name='phone'
                control={control}
                rules={{
                  validate: (value) =>
                    value ? isValidPhoneNumber(value) : true,
                }}
                render={({ field: { onChange } }) => (
                  <PhoneInput
                    className={`form-input ${
                      errors['phone'] && 'invalid-input'
                    }`}
                    placeholder='(123) 123-1234'
                    value={user?.phone}
                    onChange={onChange}
                    defaultCountry='US'
                    id='phone'
                  />
                )}
              />
            </Col>
            <Col sm='12' md='6'>
              <label className='mb-2 d-block'>ABN Number</label>
              <input
                placeholder='Enter ABN'
                className={`form-input w-100 '
                  }`}
                defaultValue={user?.ABN}
                type='number'
                {...register('ABN')}
              />
            </Col>
          </Row>

          <Row className='mb-4 '>
            <Col sm='12' md='6'>
              <label className='mb-2 d-block'>Delivery Address</label>
              <Controller
                control={control}
                name='address'
                render={({ field: { onChange } }) => (
                  <ReactGoogleAutocomplete
                    placeholder='Enter Address'
                    className={`form-input w-100 ${
                      errors['post_code'] && 'invalid-input'
                    }`}
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    onPlaceSelected={(place) => {
                      onChange(place.formatted_address);
                    }}
                    defaultValue={user?.address}
                    options={{
                      types: ['geocode', 'establishment'],
                      componentRestrictions: { country: 'aus' },
                    }}
                  />
                )}
              />
            </Col>
            <Col sm='12' md='6'>
              <label className='mb-2 d-block'>Business Name</label>
              <input
                placeholder='Enter your business name'
                className={`form-input w-100 '
                  }`}
                defaultValue={user?.business_name}
                type='text'
                {...register('business_name')}
              />
            </Col>
          </Row>
          {watch('role') === ROLES.supplier ||
          watch('role') === ROLES.retailer ? (
            <Row className='mb-4 '>
              <Col sm='12' md='6'>
                <label className='mb-2 d-block'>Account Name</label>
                <input
                  placeholder='Enter your account name'
                  className={`form-input w-100 '
                  }`}
                  defaultValue={user?.bank_account?.account_name}
                  type='text'
                  {...register('bank_account.account_name')}
                />
              </Col>
              <Col sm='12' md='6'>
                <label className='mb-2 d-block'>BSB Number</label>
                <input
                  placeholder='Enter your BSB number'
                  className={`form-input w-100 '
                  }`}
                  defaultValue={user?.bank_account?.bsb_number}
                  type='text'
                  {...register('bank_account.bsb_number')}
                />
              </Col>
            </Row>
          ) : (
            ''
          )}
          {watch('role') === ROLES.supplier ||
          watch('role') === ROLES.retailer ? (
            <Row className='mb-4 mt-2'>
              <Col sm='12' md='6'>
                <label className='mb-2 d-block'>Account Number</label>
                <input
                  placeholder='Enter your account number'
                  className={`form-input w-100 '
                  }`}
                  defaultValue={user?.bank_account?.account_number}
                  type='text'
                  {...register('bank_account.account_number')}
                />
              </Col>
            </Row>
          ) : (
            ''
          )}
          {/* btns*********************** */}

          <Row className='mb-4 d-flex justify-content-end'>
            <button
              className='btn cancel-btn'
              onClick={() => {
                history.push('/admin/user-profiles');
              }}
            >
              cancel
            </button>
            <button type='submit' className='btn success-btn next-btn'>
              Update Changes
            </button>
          </Row>
        </form>
      </div>
      <OneButtonModal
        show={resolvedModal}
        btn={'close'}
        title={'User updated'}
        message={'User has been updated successfully.'}
        click={handleClose}
      />
      <Toaster />
    </>
  );
}

export default UpdateUser;
