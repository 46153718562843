import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import './Messages.css';
import {
  bytesToSize,
  downloadFiles,
  generateUniqueId,
  getDayOrDate,
  getFileNameFromURL,
  getFilePreview,
  getSubString,
  getTenderID,
  getUploadedFilesPreview,
  isSameDay,
} from '../../services/utilities';
import { Button, Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import {
  getMyRooms,
  getRoomMessages,
  sendNewMessage,
} from '../../services/messagesService';
import { connect } from 'socket.io-client';
import moment from 'moment';
import EmojiPicker from 'emoji-picker-react';
import { uploadFiles } from '../../services/s3Service';
import { createtender } from '../../services/tenderService';
import { useHistory } from 'react-router-dom';
import SpinnerLoader from 'components/Misc/Spinner';
import SimpleModal from 'components/Modals/simple-modal/SimpleModal';
import ResolveDisputeModal from 'components/Modals/resolve-dispute/ResolveDisputeModal';
import OneButtonModal from 'components/Modals/one-button-modal/OneButtonModal';
import { TENDER_STATUSES } from 'shared/constants';
import useComponentVisible from 'hooks/useComponentVisible';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Card } from 'reactstrap';
import { IMAGES_ASSESTS_PATH } from 'shared/constants';

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};

function Messages() {
  const location = useLocation();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const MOBILE_BREAKPOINT = 767;

  const user = {
    _id: localStorage.getItem('user_id'),
    profileUrl: localStorage.getItem('profileUrl'),
  };
  const inputRef = useRef(null);

  const [resolveDisputeModal, setResolveDisputeModal] = useState(false);
  const [resolvedModal, setResolvedModal] = useState(false);

  const [rooms, setRooms] = useState();
  const [showSpinner, setSpinner] = useState(true);
  const [search, setSearch] = useState('');
  const [activeRoom, setActiveRoom] = useState();
  const [messagesList, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState([]);
  const [socket, setSocket] = useState();
  const [attachment, setAttachment] = useState();
  const [showModal, setModal] = useState(false);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const getRooms = useCallback(() => {
    getMyRooms({ search })
      .then((data) => {
        setRooms(data);
        setSpinner(false);
      })
      .catch((error) => console.log(error));
  }, [search, socket]);

  useLayoutEffect(() => {
    const newSocket = connect(
      process.env.REACT_APP_BACKEND_URL.replace('/api/v1', '')
    );
    newSocket.emit('join_room', 'ALLCHATS');
    setSocket(newSocket);

    return () => {
      newSocket.off('receive_message');
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('receive_message', (data) => {
        setMessages((prev) => [...prev, ...[data]]);
        getRoomMessages(data.roomId);
      });
      socket.on('update_rooms', () => {
        getRooms();
      });
    }
  }, [socket, getRooms]);

  useEffect(() => {
    if (socket) {
      getMyRooms()
        .then((data) => {
          if (location.state?.room) {
            const openedRoom = data.find(
              (room) => room._id === location.state?.room
            );
            openChat(openedRoom);
          }
          setRooms(data);
          setSpinner(false);
        })
        .catch((error) => console.log(error));
    }
  }, [socket]);

  const openChat = useCallback(
    async (room) => {
      setSpinner(true);
      setActiveRoom(room);
      const msgs = await getRoomMessages(room._id);
      setMessages(msgs);
      getRooms();
      socket.emit('join_room', room?._id);
      setSpinner(false);
    },
    [socket, getRooms]
  );

  const getOtherUsers = (room = activeRoom) => {
    return room?.ids?.filter((id) => id?._id !== user._id);
  };

  const getRoomDiv = (room) => {
    return (
      <>
        <div
          role="button"
          onClick={() => openChat(room)}
          className={`p-3 d-flex align-items-center justify-content-between ${
            activeRoom?._id === room._id && 'active-room'
          }`}
        >
          <div className="d-flex align-items-center">
            {getOtherUsers(room)?.map((user) => (
              <img
                key={generateUniqueId()}
                className="msg-icon"
                src={user?.profileUrl || `/images/Profle-Image.svg`}
                alt="profile"
              />
            ))}
            <div className="ml-4">
              <div>
                {getOtherUsers(room)
                  ?.map((user) => user?.name?.split(' ')[0])
                  .join(', ')}
              </div>
              {room?.latestMessage ? (
                <div>
                  {room?.latestMessage?.sender === user._id
                    ? 'You'
                    : room?.ids?.find(
                        (id) => id?._id === room?.latestMessage?.sender
                      )?.name}
                  {' : '}
                  {room?.latestMessage?.isAttachment
                    ? 'attachment'
                    : getSubString(room?.latestMessage?.content)}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {room?.unseenCount && room?.latestMessage ? (
            <small className="unseen">{room?.unseenCount}</small>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const handleKeyDownSearch = (e) => {
    if (e.key === 'Enter') {
      getRooms();
    }
  };

  const sendMessage = async () => {
    if (!newMessage.trim()) {
      return;
    }
    const newMsg = {
      roomId: activeRoom?._id,
      sender: user._id,
      content: newMessage,
      seenBy: [user._id],
    };
    await sendNewMessage(newMsg);
    socket.emit(`send_message`, newMsg);
    getRooms();
    setMessages((prev) => [
      ...prev,
      { ...newMsg, sender: { _id: user._id, profileUrl: user?.profileUrl } },
    ]);
    setNewMessage('');
  };

  const ifSamePrevSender = (index) => {
    if (!index) {
      return false;
    }
    return (
      messagesList[index]?.sender?._id === messagesList[index - 1]?.sender?._id
    );
  };

  const ifSamePrevDate = (index) => {
    if (!index) {
      return false;
    }
    return isSameDay(
      messagesList[index]?.createdAt,
      messagesList[index - 1]?.createdAt
    );
  };

  const getFileExt = (url) => {
    const parts = url?.split('.');
    return parts[parts.length - 1];
  };

  const getTime = (date) => {
    return (
      <>
        <small className="ml-3 msg-date secondary-text-color">
          {date && moment(date).format('hh:mm A')}
        </small>
      </>
    );
  };

  const downloadFile = async (url) => {
    setSpinner(true);
    await downloadFiles([url]);
    setSpinner(false);
  };

  const getMessages = () => {
    return (
      <>
        {messagesList.map((message, index) => (
          <div className="my-2" key={generateUniqueId()}>
            {!ifSamePrevDate(index) && (
              <div className="w-100 d-flex align-items-center justify-content-center">
                <span className="my-2 py-2 px-3 msg-day">
                  {getDayOrDate(message?.createdAt)}
                </span>
              </div>
            )}
            <div
              className={`w-100 d-flex align-items-center ${
                message?.sender?._id === user?._id
                  ? 'my-msg flex-row-reverse'
                  : 'justify-content-start'
              }`}
            >
              {!ifSamePrevSender(index) && (
                <img
                  className="small-icon mx-2"
                  src={
                    message?.sender?.profileUrl || `/images/Profle-Image.svg`
                  }
                  alt="profile"
                />
              )}
              <div
                className={`msg-box ${
                  ifSamePrevSender(index) && 'same-person-msg'
                }`}
              >
                {message.isAttachment ? (
                  <>
                    <div className="px-3 py-2 d-flex align-items-center">
                      <img
                        src={
                          getUploadedFilesPreview([
                            { url: message?.content?.url },
                          ])[0]?.preview
                        }
                        alt="attachment"
                        className="medium-icon mr-3"
                      />
                      <div className="mr-4">
                        <div>{getFileNameFromURL(message?.content?.url)}</div>
                        <div className="text-uppercase">
                          <span className="mr-3">
                            {getFileExt(message?.content?.url)}
                          </span>
                          <span>{bytesToSize(message?.content?.size)}</span>
                        </div>
                      </div>
                      <img
                        src="/images/Download.svg"
                        alt="download"
                        className="small-icon"
                        role="button"
                        onClick={() => downloadFile(message?.content?.url)}
                      />
                    </div>
                    <div className="w-100 text-end time-box px-2">
                      {getTime(message?.createdAt)}
                    </div>
                  </>
                ) : (
                  <div className="px-3 py-2">
                    {message?.content}
                    {getTime(message?.createdAt)}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  const setEmoji = (emoji) => {
    setNewMessage((prev) => prev + emoji.emoji);
    setIsComponentVisible(false);
  };

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    const file = await getFilePreview(fileObj);
    setAttachment(file);
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
    setAttachment(null);
    inputRef.current.files = null;
    inputRef.current.value = null;
  };

  const handleAttachmentSend = async () => {
    setSpinner(true);
    const urls = await uploadFiles([attachment], 'attachments/');
    const newMsg = {
      roomId: activeRoom?._id,
      sender: user._id,
      content: urls[0],
      seenBy: [user._id],
      isAttachment: true,
    };
    socket.emit(`send_message`, newMsg);
    sendNewMessage(newMsg);
    setMessages((prev) => [...prev, newMsg]);
    setNewMessage('');
    setSpinner(false);
    setModal(false);
  };

  const clickResolve = () => {
    setResolveDisputeModal(true);
  };

  const checkResolveDisabled = (tender) => {
    return !tender?.disputes?.find((dispute) => dispute.isClosed === false);
  };

  const handleResolve = async (reason) => {
    setSpinner(true);
    //updating tender
    await createtender({
      _id: activeRoom?.tenderId,
      status: TENDER_STATUSES.inProgress,
      'disputes.$[e1].reason': reason,
      'disputes.$[e1].isClosed': true,
      'disputes.$[e1].endDate': new Date(),
    });
    setSpinner(false);
    setResolveDisputeModal(false);
    setResolvedModal(true);
  };

  const handleResolved = () => {
    history.push(`/admin/disputes`);
    setResolvedModal(false);
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
      />
      <div className="listing-container">
        <div className="text-white mb-3">Chats</div>
        <Card>
          <>
            {!showSpinner && !rooms && !search ? (
              <>
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={`/images/Messages-large.svg`}
                    alt="Tender-Submission"
                  />
                </div>
                <h2 className="text-center mt-5">You have no messages yet</h2>
                <div className="mt-3 secondary-text-color text-center">
                  Your inbox is empty. Send a message to get started.
                </div>
              </>
            ) : (
              <>
                <Row className="messages-row">
                  {((width <= MOBILE_BREAKPOINT && !activeRoom) ||
                    width > MOBILE_BREAKPOINT) && (
                    <Col className="h-100 border-col" lg="3" md="4" sm="12">
                      <div className="d-flex align-items-center header-msgs border-col-bottom">
                        <h5 className="px-3 mb-0">Messages</h5>
                      </div>
                      <div>
                        <div className="p-3">
                          <input
                            className="w-100 px-2 search-message"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search messages"
                            onKeyDown={handleKeyDownSearch}
                          />
                        </div>
                        <div>
                          {rooms &&
                            rooms.map((room) => (
                              <div key={generateUniqueId()}>
                                {getRoomDiv(room)}
                              </div>
                            ))}
                        </div>
                      </div>
                    </Col>
                  )}
                  {((width <= MOBILE_BREAKPOINT && activeRoom) ||
                    width > MOBILE_BREAKPOINT) && (
                    <Col
                      className="h-100 border-col-right"
                      lg="9"
                      md="8"
                      sm="12"
                    >
                      {activeRoom ? (
                        <div className="h-100 msg-body">
                          <div className="d-flex align-items-center w-100 header-msgs border-col-bottom">
                            <div className="w-100 header-msgs px-3 d-flex align-items-center justify-content-between">
                              {width <= MOBILE_BREAKPOINT && (
                                <div>
                                  <img
                                    src={`/images/arrow-left.svg`}
                                    alt="back"
                                    className="small-icon"
                                    role="button"
                                    onClick={() => setActiveRoom(null)}
                                  />
                                </div>
                              )}
                              <div className="d-flex align-items-center">
                                {getOtherUsers()?.map((user) => (
                                  <img
                                    key={generateUniqueId()}
                                    className="msg-icon"
                                    src={
                                      user?.profileUrl ||
                                      `/images/Profle-Image.svg`
                                    }
                                    alt="profile"
                                  />
                                ))}
                                <div className="ml-4">
                                  {getOtherUsers()
                                    ?.map((user) => user?.name)
                                    .join(', ')}
                                </div>
                              </div>
                              {width > MOBILE_BREAKPOINT && (
                                <div>
                                  <Button
                                    className={`ml-2 py-2 px-3 border-green-btn`}
                                    disabled={checkResolveDisabled(
                                      activeRoom?.tenderId
                                    )}
                                    onClick={clickResolve}
                                  >
                                    Resolve Dispute
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="p-3 border-col-bottom">
                            <div className="d-flex align-items-center">
                              <h4 className="mb-0">
                                <span className="secondary-text-color">
                                  #{getTenderID(activeRoom?.tenderId?._id)}
                                </span>
                                <span className="px-2">{'-'}</span>
                                <span>{activeRoom?.tenderId?.title}</span>
                              </h4>
                            </div>
                            <div className="pt-3 secondary-text-color">
                              {activeRoom?.tenderId?.details}
                            </div>
                          </div>
                          <div className="msgs-content">
                            {getMessages()}
                            <AlwaysScrollToBottom />
                          </div>
                          <div className="position-relative p-3 d-flex align-items-center">
                            <img
                              className="small-icon"
                              src={`/images/Attachment.svg`}
                              alt="Attachment"
                              onClick={() => inputRef.current?.click()}
                              role="button"
                            />
                            <img
                              className="small-icon"
                              src={`/images/Emoji.svg`}
                              alt="emoji"
                              onClick={() => {
                                setIsComponentVisible(true);
                              }}
                              role="button"
                            />
                            <div
                              ref={ref}
                              className="position-absolute emoji-picker"
                            >
                              {isComponentVisible && (
                                <EmojiPicker onEmojiClick={setEmoji} />
                              )}
                            </div>
                            <div className="position-relative w-100">
                              <input
                                className="w-100 px-2 search-message type-message"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="Type a message"
                              />
                              <img
                                role="button"
                                onClick={sendMessage}
                                className="small-icon position-absolute send-btn"
                                src={`/images/Send.svg`}
                                alt="send"
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                          <div>
                            <div className="mb-5 w-100 d-flex align-items-center justify-content-center">
                              <img
                                alt="no-msg"
                                src={`${IMAGES_ASSESTS_PATH}/no-messages.svg`}
                              />
                            </div>
                            <h2 className="text-center">
                              No Preview Available
                            </h2>
                            <div>
                              Click on any chat to view the conversation.
                            </div>
                          </div>
                        </div>
                      )}
                    </Col>
                  )}
                </Row>
              </>
            )}
          </>
        </Card>
      </div>
      <SimpleModal
        show={showModal}
        handleClose={handleCloseModal}
        noClick={handleCloseModal}
        yesClick={handleAttachmentSend}
        title={'Send Attachment'}
        message={'Are you sure you want to send this attachment?'}
        img={attachment?.preview}
        enlarge
      />

      <ResolveDisputeModal
        show={resolveDisputeModal}
        handleClose={() => setResolveDisputeModal(false)}
        cancel={() => setResolveDisputeModal(false)}
        handleResolve={handleResolve}
      />
      <OneButtonModal
        show={resolvedModal}
        handleClose={() => setResolvedModal(false)}
        btn={'View Tender'}
        title={'Dispute Resolved'}
        message={'Your Dispute has successfully been resolved.'}
        click={handleResolved}
      />
    </>
  );
}

export default Messages;
