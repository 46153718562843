import React, { useState, useEffect } from 'react';
import './hired.css';
import AttachmentCard from 'components/cards/dashboard-card/attachement-card/AttachmentCard';
import { handleDownload, statusColor } from 'services/utilities';
import { fetchQuoteDetail } from 'services/adminService';
import { fetchAllUsers, suspendUserApi } from '../../services/adminService';
import ViewAttachment from 'components/Modals/view-attachment/ViewAttachment';
import { createRoom } from 'services/messagesService';
import { useHistory } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import { generateUniqueId } from 'services/utilities';
import SpinnerLoader from 'components/Misc/Spinner';
import TwoButtonModal from 'components/Modals/TwoButtonModal';
import { IMAGES_ASSESTS_PATH } from 'shared/constants';

const Hired = (props) => {
  const history = useHistory();
  const [quote, setQuote] = useState({});
  const [showSpinner, setSpinner] = useState(true);
  const [showInvoice, setShowInvoice] = useState(false);
  const [isBlocked, setIsBlocked] = useState();
  const [suspendModal, setSuspendModal] = useState(false);
  const [suspendedModal, setSuspendedModal] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    fetchQuoteDetail({ id: props.quoteId, status: 'Accepted', hired: true })
      .then((data) => {
        setQuote(data);
        setSpinner(false);
      })
      .catch((err) => console.log(err));

    if (quote?.user?._id) {
      fetchUser({
        ...(quote?.user?._id && {
          userId: quote?.user?._id,
        }),
      });
    }
  }, [suspendModal, quote?.user?._id]);

  const fetchUser = async (body) => {
    setSpinner(true);
    fetchAllUsers(body)
      .then((data) => {
        setUser(data.data);
        setSpinner(false);
      })
      .catch((err) => console.log(err));
  };

  const createRoomMessage = async () => {
    const room = await createRoom({
      ids: [quote?.user_id, localStorage.getItem('user_id')],
      tenderId: quote?.tender_id,
    });
    history.push(`/admin/messages`, { room: room._id });
  };
  const blockUser = () => {
    if (!quote.user_id) {
      alert('User not exist');
      return;
    }
    setSpinner(true);
    suspendUserApi(quote.user_id)
      .then((data) => {
        setSuspendModal(false);
        setSuspendedModal(true);
        setIsBlocked(data?.user?.isActive);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      {suspendModal && (
        <TwoButtonModal
          icon={
            quote?.user?.isActive
              ? '/dashboard-icons/Suspend.svg'
              : '/dashboard-icons/Success.svg'
          }
          title={quote?.user?.isActive ? 'Suspend User' : 'Activate User'}
          text={`Are you sure you want to ${
            quote?.user?.isActive ? 'Suspend' : 'Activate'
          } this user?`}
          btn1="No"
          btn2="Yes"
          showModal={suspendModal}
          backgroundColor={quote?.user?.isActive ? 'red' : '#527e3c'}
          handleClose={() => setSuspendModal(false)}
          handleClick1={() => setSuspendModal(false)}
          handleClick2={blockUser}
        />
      )}

      {suspendedModal && (
        <TwoButtonModal
          icon={
            quote?.user?.isActive
              ? '/dashboard-icons/Success.svg'
              : '/dashboard-icons/Suspend.svg'
          }
          title={quote?.user?.isActive ? 'User Activated' : 'User Suspended'}
          text={`User has successfully been ${
            quote?.user?.isActive ? 'Activated' : 'Suspended'
          }`}
          btn1="View Profile"
          btn2="Go To dashboard"
          showModal={suspendedModal}
          backgroundColor="#527e3c"
          handleClose={setSuspendedModal(false)}
          handleClick1={setSuspendedModal(false)}
          handleClick2={() => {
            history.push('dashboard');
          }}
        />
      )}
      <ViewAttachment
        show={showInvoice}
        file={quote?.invoice}
        handleClose={() => setShowInvoice(false)}
      />

      {quote ? (
        <div className="hired-container">
          <button
            className="btn btn-invoice"
            onClick={() => setShowInvoice(!showInvoice)}
          >
            View tax Invoice
          </button>

          <div className="user-profile-info">
            <div className="user-info px-2 py-2">
              <div className="user-information mx-1 my-1">
                <img
                  src={quote?.user?.profilUrl || '/Profle-Image.svg'}
                  alt="profile"
                  className="user-info-profile"
                />
                <div className="d-block mx-5">
                  <p className="title-heading">{quote?.user?.name}</p>
                  <div className="review-star">
                    {quote?.user?.rating?.totalRating ? (
                      <>
                        <div className="me-2">
                          <Rating
                            size={25}
                            initialValue={quote?.user?.rating?.totalRating}
                            fillColor="green"
                            readonly
                          />
                        </div>
                        <div>
                          {parseInt(quote?.user?.rating?.totalRating).toFixed(
                            1
                          )}{' '}
                          of {quote?.user?.rating?.count} review(s)
                        </div>
                      </>
                    ) : (
                      <>No ratings</>
                    )}
                  </div>
                  <div className="msg-block-btn d-flex mt-3">
                    <button
                      onClick={createRoomMessage}
                      className="msg-btn btn "
                    >
                      Message
                    </button>
                    <button
                      className="block-btn btn"
                      onClick={() => setSuspendModal(true)}
                      disabled={!quote.user_id}
                    >
                      {quote?.user?.isActive === true || isBlocked === true
                        ? 'Suspend'
                        : 'UnSuspend'}
                    </button>
                  </div>
                </div>
              </div>
              <div className="user-stat d-flex justify-content-between align-items-center mt-5 px-2 ">
                <div className="d-block mx-2">
                  <h4 className="title-value text-center">
                    {(user && user[0]?.totalActiveTenders) || 'N/A'}
                  </h4>
                  <p className="title-text ">Total Tenders Fulfilled</p>
                </div>
                <div className="d-block  mx-2">
                  <h4 className="title-value text-center">
                    {(user && user[0]?.totalAmount) || 'N/A'}
                  </h4>
                  <p className="title-text">Total Amount Spent</p>
                </div>
                <div className="d-block mx-2">
                  <h4 className="title-value text-center">
                    {(user && user[0]?.tender_id?.length) || 'N/A'}
                  </h4>
                  <p className="title-text">Active Orders</p>
                </div>
              </div>
            </div>
            <div className="profile-info px-2 py-2">
              <p className="title-heading border-bottom px-2 py-2">
                Profile Info
              </p>
              <div className="upper-row px-2 py-2 ">
                <div>
                  <div>
                    <p className="title-text">Email</p>
                    <p className="title-value">{quote?.user?.email}</p>
                  </div>
                  <div className="mt-5">
                    <p className="title-text">Delivery Address</p>
                    <p className="title-value">{quote?.post_code}</p>
                  </div>
                </div>

                <div>
                  <div>
                    <p className="title-text">Phone</p>
                    <p className="title-value">{quote?.user?.phone}</p>
                  </div>
                  <div className="mt-5">
                    <p className="title-text">Buisness Name</p>
                    <p className="title-value">{quote?.user?.business_name}</p>
                  </div>
                </div>

                <div>
                  <div>
                    <p className="title-text">ABN</p>
                    <p className="title-value">{quote?.user?.ABN || 'N/A'}</p>
                  </div>
                  <div className="mt-5">
                    <p className="title-text">BSB Number</p>
                    <p className="title-value">{quote?.user?.BSB || 'N/A'}</p>
                  </div>
                </div>

                <div>
                  <div>
                    <p className="title-text">Account Name</p>
                    <p className="title-value">{quote?.user?.name}</p>
                  </div>
                  <div className="mt-5">
                    <p className="title-text ml-2">Account Number</p>
                    <p className="title-value">
                      {quote?.user?.account_number || 'N/A'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="quote-info mt-3 px-2 py-2">
            <div className="title-heading border-bottom px-2 py-2">
              Supplier Offer
            </div>
            <div className="d-flex justify-content-between mx-2 my-3">
              <div className="d-flex justify-content-between align-items-center w-50">
                <div>
                  <p className="title-text">Delivery/Pickup Post Code</p>
                  <p className="titlquote-infoe-value">{quote?.post_code}</p>
                </div>
                <div>
                  <p className="title-text">Delivery/Pickup Date</p>
                  <p className="title-value">{quote?.completion_date}</p>
                </div>
                <div>
                  <p className="title-text">Total Price (Includes GST)</p>
                  <p className="title-value">${quote?.price}</p>
                </div>
              </div>
              <div className="tender-status">
                <span
                  style={{
                    color: statusColor(quote?.status) || 'blue',
                  }}
                  className="tender-status-dot"
                >
                  .
                </span>
                <span>{quote?.status || 'N/A'}</span>
              </div>
            </div>

            <div className="title-text mt-3 mx-2">Order Details</div>
            <div className="title-value mx-2">{quote?.details}</div>

            {quote?.attachments?.length ? (
              <div className="mt-3 mx-2">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="title-text">Attachments</div>
                  <div
                    onClick={handleDownload}
                    role="button"
                    className="primary-color"
                  >
                    <span style={{ color: '#527e3c' }}>Download all</span>
                    <img
                      className="download-icon ms-2 mb-2"
                      src={'/Download.svg'}
                      alt="download"
                    />
                  </div>
                </div>

                <div className="d-flex flex-wrap">
                  {quote?.attachments.map((attachment) => (
                    <AttachmentCard
                      key={generateUniqueId()}
                      attachment={attachment}
                      setSpinner={setSpinner}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div className="quote-info my-5 py-5">
          <div className="w-100 d-flex justify-content-center">
            <img src={`${IMAGES_ASSESTS_PATH}/hired-none.svg`} alt="none" />
          </div>
          <div>
            <h3 className="mt-5 text-center">Not Hired</h3>
            <div className="mt-3 text-center secondary-text-color">
              No quote has been accepted yet
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Hired;
