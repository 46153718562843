import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import './DashboardCard.css';

const DashboardCard = ({ title, count, icon, increment, increased }) => {
  return (
    <>
      <div className="dashboard-card border-round">
        <div className="d-flex justify-content-between align-items-center  mx-3">
          <div className="mt-3">
            <div className="title-card">{title}</div>
            <div className="card-count">{count}</div>
          </div>

          <div className="card-icon">
            <img src={icon} alt="blabla" />
          </div>
        </div>

        <div className="d-flex align-items-center mb-1 mx-2">
          <span className="up-arrow-icon ml-1 my-2">
            {increased ? (
              <img src="/Upward-Arrow.svg" alt="kakakka" />
            ) : (
              <img src="/decrement.svg" alt="kakakka" />
            )}
          </span>
          <span
            className="card-increment card-text ml-1 mr-2 my-2"
            style={increased ? { color: '#527e3c' } : { color: 'red' }}
          >
            {increment}%
          </span>
          <span className="card-detail card-text my-2">since last month</span>
        </div>
      </div>
    </>
  );
};

export default DashboardCard;
