import React from 'react';
import './OneButtonModal.css';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import Lottie from 'react-lottie-player';
import loadingLoti from '../../../assets/loti/Loading.json';

function OneButtonModal({
  show,
  handleClose,
  loading,
  click,
  title,
  message,
  icon,
  btn,
  successBtn
}) {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="my-4 d-flex align-items-center justify-content-center">
            {loading ? (
              <Lottie
                className="loading-loti"
                loop
                animationData={loadingLoti}
                play
              />
            ) : (
              <img
                src={icon || `/images/Completed.svg`}
                alt="success"
                className="modal-img"
              />
            )}
          </div>
          <div className="mb-4">
            <h4 className="text-center">{title}</h4>
            <div className="text-center">{message}</div>
          </div>
          <div className="mb-4 d-flex align-items-center justify-content-center">
            <Button onClick={click} className={`${successBtn ? 'success' : 'primary-btn'}`}>
              {btn}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OneButtonModal;
