import React, { useEffect, useState } from 'react';
import './Notifications.css';
import { useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import {
  generateUniqueId,
  getNotifiNavigate,
  getNotificationImg,
} from '../../services/utilities';
import moment from 'moment';
import Pages from '../pagination/Pagination';
import {
  fetchNotifications,
  markRead,
  updateNotification,
} from '../../services/notificationService';
import _ from 'lodash';
import SpinnerLoader from 'components/Misc/Spinner';
import { IMAGES_ASSESTS_PATH, defaultPageMeta } from '../../shared/constants';
import { Card } from 'reactstrap';

function Notifications() {
  const history = useHistory();
  const [meta, setMeta] = useState(defaultPageMeta);
  const [notifications, setNotifications] = useState([]);
  const [showSpinner, setSpinner] = useState(true);
  const [showRead, setShowRead] = useState(false);

  const getNotifications = (params) => {
    fetchNotifications(params)
      .then((data) => {
        setNotifications(data.data || []);
        setMeta(data.meta);
        setSpinner(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getNotifications({
      size: defaultPageMeta.per_page,
      pageNo: defaultPageMeta.current_page,
      showRead,
    });
  }, [showRead]);

  const pageClick = (pageNo) => {
    setMeta((prev) => {
      return { ...prev, current_page: pageNo };
    });
    getNotifications({
      size: meta.per_page,
      pageNo,
      showRead,
    });
  };

  const pageSize = (size) => {
    setMeta((prev) => {
      return { ...prev, per_page: size };
    });
    getNotifications({
      size,
      pageNo: 1,
      showRead,
    });
  };

  const navigateNotification = async (notif) => {
    setSpinner(true);
    await updateNotification({
      _id: notif._id,
      isRead: true,
    });
    const url = getNotifiNavigate(notif?.type, notif?.data);
    url && history.push(url);
    setSpinner(false);
  };

  const clickToggle = () => {
    setShowRead((prev) => !prev);
  };

  const markAllRead = async () => {
    setSpinner(true);
    await markRead();
    setNotifications((prev) => {
      return prev.map((notif) => {
        let newNotif = _.cloneDeep(notif);
        newNotif.isRead = true;
        return newNotif;
      });
    });
    setSpinner(false);
  };

  return (
    <>
      <div className="listing-container">
        <Card>
          <div className="p-0">
            <div className="notif-header border-bottom d-flex align-items-center justify-content-between">
              <h5 className="mb-0 bold-text">
                Notifications{' '}
                {notifications.length > 0 && (
                  <span>{`(${notifications.length})`}</span>
                )}
              </h5>
              <div className="d-flex">
                <small
                  role="button"
                  onClick={clickToggle}
                  className="d-flex align-items-center"
                >
                  <label className="custom-toggle custom-toggle-primary mr-2">
                    <input
                      type="checkbox"
                      checked={showRead}
                      onChange={clickToggle}
                    />
                    <span className="custom-toggle-slider rounded-circle"></span>
                  </label>
                  Only show unread
                </small>
                <small onClick={markAllRead} role="button" className="ml-4">
                  Mark all as read
                  <span>
                    <img
                      src={`${IMAGES_ASSESTS_PATH}/read-notif.svg`}
                      alt="read"
                    />
                  </span>
                </small>
              </div>
            </div>
            <div>
              {notifications.length ? (
                notifications.map((notif) => (
                  <div
                    onClick={() => navigateNotification(notif)}
                    role="button"
                    key={generateUniqueId()}
                    className={`py-3 px-5 border-bottom ${
                      !notif?.isRead && 'unread-notif'
                    }`}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src={getNotificationImg(notif?.type, notif?.data)}
                        alt="profile"
                        className="small-icon mr-2 border-50"
                      />
                      <div>{notif?.title}</div>
                    </div>
                    <div className="notif-date mt-2 d-flex align-items-center justify-content-between">
                      <small className="secondary-text-color">
                        {moment(notif?.createdAt).format('DD-MM-YYYY')}
                      </small>
                    </div>
                  </div>
                ))
              ) : (
                <div className="p-5 text-center">No Notifications</div>
              )}
            </div>
          </div>
          {notifications && notifications.length ? (
            <Pages
              meta={meta}
              pageClick={pageClick}
              pageSize={pageSize}
              defaultPage={5}
            />
          ) : (
            <></>
          )}
        </Card>
      </div>
      <SpinnerLoader showSpinner={showSpinner} />
    </>
  );
}

export default Notifications;
