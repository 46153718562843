import React, { useState } from 'react';
import './ResolveDisputeModal.css';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

function ResolveDisputeModal({ show, handleClose, handleResolve, cancel }) {
  const [reason, setReason] = useState('');

  const handleResolveClick = () => {
    if (!reason) {
      return;
    }
    handleResolve(reason);
  };

  return (
    <div>
      <Modal className="reason-modal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <h4 className="mt-4">Resolve Dispute</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <div className="secondary-text-color">
              Tell us the reason of dispute.
            </div>
            <div className="mt-4">
              <label className="mb-2">
                Reason <span className="required-color">*</span>
              </label>
              <textarea
                placeholder="Enter details"
                className={`form-input w-100`}
                rows={'10'}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
            <div className="mt-2 w-100 d-flex align-items-center justify-content-end">
              <Button
                onClick={cancel}
                className={`border-btn me-2 text-nowrap`}
              >
                Cancel
              </Button>
              <Button
                onClick={handleResolveClick}
                className={`primary-btn text-nowrap`}
              >
                Resolve
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ResolveDisputeModal;
