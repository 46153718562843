import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
} from 'reactstrap';
import { logout } from 'services/authService';
import notification from '../../assets/img/icons/Notification.svg';
import emailIcon from '../../assets/img/icons/Email(1).svg';
import { connect } from 'socket.io-client';
import { fetchNotifications } from 'services/notificationService';
import { getMyRooms } from 'services/messagesService';

function AdminNavbar({ theme }) {
  const history = useHistory();
  const [profileUrl, setProfileUrl] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [socket, setSocket] = useState();
  const [unseenMsg, setUnseenMsg] = useState();
  const [unseenNotif, setUnseenNotif] = useState();

  //handling lagout
  const handleLogout = (e) => {
    e.preventDefault();
    logout()
      .then(() => {
        history.push('/auth/login');
      })
      .catch((err) => {
        console.log(err);
        history.push('/auth/login');
      });
  };

  // handling notification Icon
  const handleNotifications = () => {
    history.push('/admin/notifications');
  };

  // const handle chats
  const handleChats = () => {
    history.push('/admin/messages');
  };

  const getRooms = useCallback(() => {
    getMyRooms()
      .then((data) => {
        const sum = data.reduce((accumulator, object) => {
          return (
            accumulator +
            (object?.latestMessage?.content ? object.unseenCount : 0)
          );
        }, 0);
        setUnseenMsg(sum);
      })
      .catch((error) => console.log(error));
  }, []);

  useLayoutEffect(() => {
    console.log(
      'socket: ',
      process.env.REACT_APP_BACKEND_URL.replace('/api/v1', '')
    );
    const newSocket = connect(
      process.env.REACT_APP_BACKEND_URL.replace('/api/v1', '')
    );
    newSocket.emit('join_room', 'ALLCHATS');
    setSocket(newSocket);

    return () => {
      newSocket.off('receive_message');
      newSocket.disconnect();
    };
  }, []);

  const getNewNotifications = () => {
    fetchNotifications()
      .then((data) => {
        setUnseenNotif(data.unseenCount);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getNewNotifications();
    getRooms();
  }, [getRooms]);

  useEffect(() => {
    if (socket) {
      socket.on('update_rooms', () => {
        getRooms();
      });
      socket.on('update_notification', () => {
        getNewNotifications();
      });
      socket.on('update_profile', (body) => {
        body?.profileUrl && setProfileUrl(body?.profileUrl);
      });
    }
  }, [socket]);

  useEffect(() => {
    setProfileUrl(localStorage.getItem('profileUrl'));
    setUsername(localStorage.getItem('company'));
    setEmail(localStorage.getItem('email'));
  }, []);

  const checkURL = (url) => {
    try {
      return new URL(url);
    } catch (_) {
      return false;
    }
  };

  return (
    <>
      <Navbar
        className={classnames(
          'navbar-top navbar-expand-end',
          { 'navbar-success bg-info-bh': theme === 'dark' },
          { 'navbar-light bg-secondary': theme === 'light' }
        )}
      >
        <div className="d-flex justify-content-end w-100 pr-4">
          <div className="d-flex align-items-center">
            {/* notification icon */}
            <span className="position-relative mx-3">
              <img
                src={emailIcon}
                onClick={handleChats}
                className="adminIcons mx-3"
                role="button"
              />
              {unseenMsg ? (
                <small className="position-absolute count-unseen-msg">
                  {unseenMsg}
                </small>
              ) : (
                <></>
              )}
            </span>

            {/* notification icon */}
            <span className="position-relative mr-2">
              <img
                src={notification}
                className="adminIcons"
                onClick={(e) => handleNotifications()}
                role="button"
              />
              {unseenNotif ? (
                <small className="position-absolute count-unseen">
                  {unseenNotif}
                </small>
              ) : (
                <></>
              )}
            </span>

            <div className="mx-2 ">
              <UncontrolledDropdown nav>
                <DropdownToggle
                  role="button"
                  className="nav-link pr-0"
                  color=""
                  tag="a"
                >
                  <Media className="align-items-center ">
                    <>
                      {checkURL(profileUrl) ? (
                        <img
                          src={profileUrl}
                          className="admin-profile-pic mr-1"
                        />
                      ) : (
                        <img
                          src={'/Profle-Image.svg'}
                          className="admin-profile-pic mr-1"
                        />
                      )}
                    </>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm text-white font-weight-bold d-flex align-items-center">
                        Admin
                        <i className="ml-2 fa fa-chevron-down"></i>
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <div className="d-flex align-items-center">
                      <div className="mr-3">
                        <i className="fa fa-user icon-color-light text-lg"></i>
                      </div>
                      <div>
                        <small>
                          <b>{username || 'Admin'}</b>
                        </small>
                        <br />
                        <small>{email || 'N/A'}</small>
                      </div>
                    </div>
                  </DropdownItem>
                  <DropdownItem className="my-1" divider />
                  <DropdownItem onClick={(e) => history.push('/admin/profile')}>
                    <i className="fa fa-lock" />
                    <span>Profile</span>
                  </DropdownItem>
                  <DropdownItem className="my-1" divider />
                  <DropdownItem onClick={(e) => history.push('/admin/setting')}>
                    <i className="fa fa-lock" />
                    <span>Settings</span>
                  </DropdownItem>
                  <DropdownItem className="my-1" divider />
                  <DropdownItem onClick={(e) => handleLogout(e)}>
                    <i className="fa fa-sign-out-alt mirror" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        </div>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark',
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
};

export default AdminNavbar;
