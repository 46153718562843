import React, { useEffect, useState } from 'react';
import './quoteDetail.css';
import { fetchQuoteDetail } from '../../services/adminService';
import {
  generateUniqueId,
  handleDownload,
  statusColor,
} from '../../services/utilities';
import AttachmentCard from 'components/cards/dashboard-card/attachement-card/AttachmentCard';

const QuoteDetail = (props) => {
  const [quote, setQuote] = useState({});
  const [showSpinner, setSpinner] = useState(true);

  useEffect(() => {
    fetchQuoteDetail({ id: props.quoteId, detail: true })
      .then((data) => {
        setQuote(data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="hired-container">
      <div className="quote-info mt-3 px-2 py-2">
        <div className="title-heading border-bottom px-2 py-2">
          Supplier Offer
        </div>
        <div className="d-flex justify-content-between mx-2 my-3">
          <div className="d-flex justify-content-between align-items-center w-50">
            <div>
              <p className="title-text">Delivery/Pickup Post Code</p>
              <p className="title-value">{quote?.post_code}</p>
            </div>
            <div>
              <p className="title-text">Delivery/Pickup Date</p>
              <p className="title-value">{quote?.completion_date}</p>
            </div>
            <div>
              <p className="title-text">Total Price (Includes GST)</p>
              <p className="title-value">${quote?.price}</p>
            </div>
          </div>
          <div className="tender-status">
            <span
              style={{
                color: statusColor(quote?.status) || 'blue',
              }}
              className="tender-status-dot"
            >
              .
            </span>
            <span>{quote?.status || 'N/A'}</span>
          </div>
        </div>

        <div className="title-text mt-3 mx-2">Order details</div>
        <div className="title-value mx-2">{quote?.details}</div>

        {quote?.attachments?.length ? (
          <div className="mt-3 mx-2">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="title-text">Attachments</div>
              <div
                onClick={() => handleDownload(setSpinner, quote?.attachments)}
                role="button"
                className="primary-color"
              >
                <span style={{ color: '#527e3c' }}>Download all</span>
                <img
                  className="download-icon ms-2 mb-2"
                  src={'/Download.svg'}
                  alt="download"
                />
              </div>
            </div>

            <div className="d-flex flex-wrap">
              {quote?.attachments.map((attachment) => (
                <AttachmentCard
                  key={generateUniqueId()}
                  attachment={attachment}
                  setSpinner={setSpinner}
                />
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default QuoteDetail;
