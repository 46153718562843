import {
  authorizedGetCall,
  authorizedPutCall,
  authorizedPostCall,
} from './APIsService';

export const countTenders = () => {
  return new Promise((resolve, reject) => {
    authorizedGetCall('/tender/count-tenders')
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchTenders = async (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall('/tender/all-tenders', body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAdminStat = async () => {
  return new Promise((resolve, reject) => {
    authorizedGetCall('/user/stat')
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchQuoteDetail = async (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/quote/quote-detail/${body.id}`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchQuotes = async (id, body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/quote/all-quotes/${id}`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getOneTender = async (id) => {
  return new Promise((resolve, reject) => {
    authorizedGetCall(`/tender/tender-by-id/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getOneUser = async (id) => {
  return new Promise((resolve, reject) => {
    authorizedGetCall(`/user/get_profile/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getOneQuote = async (id) => {
  return new Promise((resolve, reject) => {
    authorizedGetCall(`/quote/get-one-quote/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchAllUsers = async (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall('/user/all_users', body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changePasswordApi = async (body) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall('/user/change_password', body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const suspendUserApi = async (userId) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall(`/user/suspend_user/${userId}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const changeRoleApi = async (userId) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall(`/user/change_role/${userId}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getInvoiceDetail = async (id) => {
  return new Promise((resolve, reject) => {
    authorizedGetCall(`/quote/invoice-detail-admin/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchTransactions = async (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/quote/get-transactions`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const markPaidApi = async (id) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall(`/quote/mark-paid/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const setTransactionAmountApi = async (id, body) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall(`/quote/transaction-amount/${id}`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchDisputesApi = async (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/tender/all-disputes`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updatequote = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall(`/quote/${body._id}`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
