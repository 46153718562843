import { authorizedGetCall, authorizedPostCall } from './APIsService';

export const getMyRooms = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/messages/rooms`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createRoom = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/messages/create-room`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getRoomMessages = (roomId) => {
  return new Promise((resolve, reject) => {
    authorizedGetCall(`/messages/room-messages/${roomId}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sendNewMessage = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/messages/send`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
