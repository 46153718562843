import React, { useState } from 'react';
import './userProfile.css';
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Row,
  Input,
} from 'reactstrap';
// core components
import { fetchAllUsers } from 'services/adminService';
import SpinnerLoader from 'components/Misc/Spinner';
import FilterModal from 'components/Modals/FilterModal';
import UserProfile from './UserProfile';
import { Rating } from 'react-simple-star-rating';
import { generateUniqueId } from 'services/utilities';
import DeleteModal from 'components/Modals/deleteModal';
import { removeUser } from 'services/authService';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import editIcon from '../../assets/img/icons/Edit.svg';

function UserProfiles() {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showSpinner, setSpinner] = useState(true);
  const [pageSize, setPageSize] = useState(30);
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [showTenderDetail, setShowTenderDetail] = useState(false);
  const [role, setRole] = useState('farmer');
  const [user, setUser] = useState();
  const [buttonId, setButtonId] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState('');
  const [sort, setSort] = useState('');

  const defaultFilters = {
    pageNo: currentPage,
    size: pageSize,
    ...(search.trim() && {
      search: search.trim(),
    }),
    ...(role && {
      role: role,
    }),
    ...(filters.fromDate && {
      fromDate: filters.fromDate,
    }),
    ...(filters.toDate && {
      toDate: filters.toDate,
    }),
    ...(filters.zipCode && {
      zipCode: filters.zipCode,
    }),
    ...(filters.range && {
      range: filters.range,
    }),
    ...(filters.status && {
      status: filters.status,
    }),

    sort,
  };

  React.useEffect(() => {
    fetchUser(defaultFilters);
  }, [filters, role, sort]);

  const fetchUser = async (body) => {
    setSpinner(true);
    fetchAllUsers(body)
      .then((data) => {
        setUsers(data.data);
        setCount(data.meta.total_count);
        setSpinner(false);
      })
      .catch((err) => console.log(err));
  };

  const pages = () => {
    let pagesArr = [];
    for (let i = 1; i <= Math.ceil(count / pageSize); i++) {
      pagesArr.push(
        <PaginationItem className={currentPage === i ? 'active' : ''}>
          <PaginationLink onClick={(e) => handlePageClick(e, i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return pagesArr;
  };

  const handlePageClick = (e, pageNumber) => {
    e.preventDefault();
    fetchUser({
      pageNo: pageNumber,
      size: pageSize,
      ...(search.trim() && {
        search: search.trim(),
      }),
    });
    setPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    fetchUser({
      pageNo: currentPage,
      size: pageSize,
      search: e.target.value.trim(),
    });
  };

  const onEntriesSelect = (e) => {
    setPageSize(parseInt(e.target.value));
    fetchUser({
      pageNo: currentPage,
      size: pageSize,
      ...(search.trim() && {
        search: search.trim(),
      }),
    });
  };

  const handleFilters = () => {
    setShowModal(!showModal);
  };

  const tableDataHeader = [
    `${role.toUpperCase()} NAME`,
    'EMAIL',
    'ACTIVE ORDERS',
    'POST CODE',
    'RATING',
    'AMOUNT SPENT',
    'STATUS',
    'ACTIONS',
  ];

  const handleDeleteUser = () => {
    setSpinner(true);
    removeUser(deleteUserId)
      .then(() => {
        fetchUser(defaultFilters);
        setDeleteModal(false);
      })
      .catch((error) => {
        setSpinner(false);
        console.log(error);
      });
  };

  return (
    <>
      {showTenderDetail ? (
        <UserProfile user={user} />
      ) : (
        <div>
          <SpinnerLoader showSpinner={showSpinner} />
          <div className=' listing-container '>
            {showModal && (
              <FilterModal
                showModal={showModal}
                handleClose={handleFilters}
                setFilters={setFilters}
                statusId={4}
              />
            )}
            <div className='text-white mb-3'>User Profiles</div>
            <div className='my-3'>
              <button
                className='btn '
                style={
                  buttonId === 1
                    ? { background: 'white', color: '#527e3c' }
                    : { background: '#648b50', color: 'white' }
                }
                onClick={() => {
                  setButtonId(1);
                  setRole('farmer');
                }}
              >
                Farmer
              </button>
              <button
                className='btn '
                style={
                  buttonId === 2
                    ? { background: 'white', color: '#527e3c' }
                    : { background: '#648b50', color: 'white' }
                }
                onClick={() => {
                  setButtonId(2);
                  setRole('supplier');
                }}
              >
                Supplier
              </button>
              <button
                className='btn '
                style={
                  buttonId === 3
                    ? { background: 'white', color: '#527e3c' }
                    : { background: '#648b50', color: 'white' }
                }
                onClick={() => {
                  setButtonId(3);
                  setRole('retailer');
                }}
              >
                Retailer
              </button>
            </div>
            <div className=''>
              <Row>
                <div className='col'>
                  <Card>
                    <CardHeader className='border-0'>
                      {/* card header filter action and tender listing heading */}
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='tender-listing-heading'>
                          User Listings
                        </div>
                        <div>
                          <button
                            className='btn border border-dark'
                            onClick={handleFilters}
                          >
                            <img src='/dashboard-icons/Filters.svg' /> Filters
                          </button>
                          <div className='dropdown'>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant='success'
                                id='dropdown-basic'
                                className='btn btn-white sort-by-btn'
                              >
                                <img
                                  src='/images/arrow-down-up.svg'
                                  alt='sort'
                                  className='icon-small mr-2'
                                />
                                Sort By
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => setSort('totalActiveTenders')}
                                >
                                  Active Orders
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => setSort('totalAmount')}
                                >
                                  Amount
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>

                      {/* Card header 2nd row for page entries and search action */}
                      <div className='mt-3 d-flex align-items-center justify-content-between'>
                        <div className='pageEntry'>
                          <small>
                            Show
                            <select
                              defaultValue={10}
                              onChange={onEntriesSelect}
                              className='pl-1 pr-3 ml-1'
                            >
                              <option value={10}>10</option>
                              <option value={15}>15</option>
                              <option value={20}>20</option>
                            </select>
                            <i className='fa fa-sort text-xxs select-options'></i>
                            <span className='ml-2'>entires</span>
                          </small>
                        </div>

                        <div className='d-flex align-items-center'>
                          <div className='d-inline-block mr-4 search-color'>
                            Search:
                          </div>
                          <Input
                            className='d-inline-block listing-search-box'
                            placeholder='Search Title'
                            type='text'
                            value={search}
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                    </CardHeader>
                    <div className='table-responsive'>
                      <Table className='dataTable align-items-center'>
                        <thead className='table-head icon-color-light'>
                          <tr>
                            {tableDataHeader.map((header) => {
                              return (
                                <th
                                  key={generateUniqueId()}
                                  className='px-0 w-12 border '
                                  scope='col'
                                >
                                  {header}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody className='list'>
                          {users.map((user, index) => (
                            <tr
                              key={generateUniqueId()}
                              style={{
                                background:
                                  index % 2 == 0 ? '#EDF5EA4D' : 'white',
                              }}
                            >
                              <td className='pl-2'>
                                <div className='overflowStyle d-flex justify-content-start align-items-center farmer-name '>
                                  {user?.profileUrl ? (
                                    <img
                                      src={user?.profileUrl}
                                      className='mr-1 profile-pic'
                                    />
                                  ) : (
                                    <img
                                      src='/Profle-Image.svg'
                                      className='mr-1 profile-pic'
                                    />
                                  )}
                                  {user?.name}
                                </div>
                              </td>
                              <td className='text-dark overflowStyle px-1'>
                                {user?.email}
                              </td>
                              <td className='text-dark overflowStyle px-1 w-5'>
                                {user?.totalActiveTenders}
                              </td>
                              <td className='px-1'>{user?.address}</td>
                              <td className='px-1 w-10'>
                                <div className='d-flex flex-column align-items-center justify-content-between'>
                                  {user?.rating?.totalRating ? (
                                    <>
                                      <div className='me-2'>
                                        <Rating
                                          size={25}
                                          initialValue={
                                            user?.rating?.totalRating
                                          }
                                          fillColor='green'
                                          readonly
                                        />
                                      </div>
                                      <div>
                                        {parseInt(
                                          user?.rating?.totalRating
                                        ).toFixed(1)}{' '}
                                        of {user?.rating?.count} review(s)
                                      </div>
                                    </>
                                  ) : (
                                    <>No ratings</>
                                  )}
                                </div>
                              </td>
                              <td className='px-1 w-5'>${user?.totalAmount}</td>
                              <td className='px-1 w-5 '>
                                <div className='d-flex justify-content-center align-items-center'>
                                  <span
                                    style={{
                                      backgroundColor: user?.isActive
                                        ? 'green'
                                        : 'red',
                                    }}
                                    className='tender-status-dot'
                                  ></span>
                                  {user?.isActive ? 'Active' : 'Inactive'}
                                </div>
                              </td>
                              <td className='pr-2'>
                                <div>
                                  <img
                                    className='tendersImageTable'
                                    alt='...'
                                    src={editIcon}
                                    onClick={async () => {
                                      history.push(`user-update/${user._id}`);
                                    }}
                                    role={'button'}
                                  />
                                  <img
                                    className='tendersImageTable'
                                    alt='...'
                                    src='/dashboard-icons/View.svg'
                                    onClick={() => {
                                      setUser(user);
                                      setShowTenderDetail(true);
                                    }}
                                    role={'button'}
                                  />
                                  <img
                                    className='tendersImageTable'
                                    alt='...'
                                    src='/dashboard-icons/Delete-Icon-Normal.svg'
                                    onMouseOver={(e) =>
                                      (e.currentTarget.src =
                                        '/dashboard-icons/Delete-Icon-Hover.svg')
                                    }
                                    onMouseLeave={(e) =>
                                      (e.currentTarget.src =
                                        '/dashboard-icons/Delete-Icon-Normal.svg')
                                    }
                                    onClick={() => {
                                      setDeleteUserId(user._id);
                                      setDeleteModal(true);
                                    }}
                                    role={'button'}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                          <tr></tr>
                        </tbody>
                      </Table>
                      {!showSpinner && !users.length && (
                        <div className='d-flex align-items-center justify-content-center mb-3'>
                          No records found
                        </div>
                      )}
                    </div>
                    <CardFooter className=''>
                      <Pagination
                        className='pagination justify-content-end mb-0'
                        listClassName='justify-content-end mb-0'
                      >
                        <PaginationItem
                          className={currentPage === 1 ? 'disabled' : ''}
                        >
                          <PaginationLink
                            onClick={(e) => handlePageClick(e, currentPage - 1)}
                            tabIndex='-1'
                          >
                            <i className='fas fa-angle-left' />
                            <span className='sr-only'>Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        {pages().map((page) => (
                          <div key={generateUniqueId()}>{page}</div>
                        ))}
                        <PaginationItem
                          className={
                            currentPage >= Math.ceil(count / pageSize)
                              ? 'disabled'
                              : ''
                          }
                        >
                          <PaginationLink
                            onClick={(e) => handlePageClick(e, currentPage + 1)}
                          >
                            <i className='fas fa-angle-right' />
                            <span className='sr-only'>Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </CardFooter>
                  </Card>
                </div>
              </Row>
            </div>
          </div>
        </div>
      )}
      <DeleteModal
        title='Delete User'
        message='Are you sure to delete User?'
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleDelete={handleDeleteUser}
      />
    </>
  );
}

export default UserProfiles;
