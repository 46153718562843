import React, { useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import DashboardCard from 'components/cards/dashboard-card/DashboardCard';
import './Dashboard.css';
import { Line } from 'react-chartjs-2';
import { getAdminStat } from '../../services/adminService';
import SpinnerLoader from 'components/Misc/Spinner';

function Dashboard() {
  const [chartData, setChartData] = useState([]);
  const [totalTenders, setTotalTenders] = useState({});
  const [totalUsers, setTotalUsers] = useState([]);
  const [totalRevnue, setTotalRevnue] = useState([]);
  const [monthRevnue, setMonthRevnue] = useState([]);
  const [weekRevnue, setWeekRevnue] = useState([]);
  const [annualRevnue, setAnnualRevnue] = useState();
  const [btnId, setBtnId] = useState(1);
  const [showSpinner, setSpinner] = useState(true);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'April',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const weeks = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
  ];
  const [chartLabels, setChartLabels] = useState(months);

  const [farmerChange, setFarmerChange] = useState({
    increment: false,
    percentage: 0,
  });
  const [supplierChange, setSupplierChange] = useState({
    increment: false,
    percentage: 0,
  });
  const [tendersChange, setTendersChange] = useState({
    increment: false,
    percentage: 0,
  });
  const [revnueChange, setRevnueChange] = useState({
    increment: false,
    percentage: 0,
  });

  const handleMonth = () => {
    setBtnId(1);
    setChartLabels(months);
    setChartData(annualRevnue);
  };

  const handleWeek = () => {
    setBtnId(2);
    setChartLabels(weeks);
    setChartData(weekRevnue);
  };

  const handleYear = () => {
    setBtnId(0);
    setChartLabels(months);
    setChartData(annualRevnue);
  };

  useEffect(() => {
    // it will be modified later as multiple APIs required
    getAdminStat().then((data) => {
      setTotalUsers(data?.totalUsers);
      setTotalTenders(data?.totalTenders);
      setTotalRevnue(data?.totalRevnue);
      setMonthRevnue(data?.monthRevnue[0]?.data);
      setWeekRevnue(data?.weekRevnue[0]?.data);
      setAnnualRevnue(data?.totalRevnue[0]?.last12MonthsTotal);
      setChartData(data?.totalRevnue[0]?.last12MonthsTotal);
      setSpinner(false);
    });
  }, []);

  const getRoleCount = (role) => {
    return totalUsers.find((user) => user.role === role);
  };

  useEffect(() => {
    // setting the farmers change
    const farmers = getRoleCount('farmer');
    setFarmerChange(
      calculatePercentage(
        farmers?.currentMonthCount,
        farmers?.lastMonthCount,
        farmers?.totalCount
      )
    );

    // setting the supplier change
    const suppliers = getRoleCount('supplier');
    setSupplierChange(
      calculatePercentage(
        suppliers?.currentMonthCount,
        suppliers?.lastMonthCount,
        suppliers?.totalCount
      )
    );

    // setting the tenders change
    setTendersChange(
      calculatePercentage(
        totalTenders?.currentMonthCount,
        totalTenders?.lastMonthCount,
        totalTenders?.totalCount
      )
    );

    // setting the revnue change
    setRevnueChange(
      calculatePercentage(
        totalRevnue[0]?.last12MonthsTotal.slice(-1),
        totalRevnue[0]?.last12MonthsTotal.slice(2),
        totalRevnue[0]?.totalPrice
      )
    );
  }, [totalUsers, totalTenders, totalRevnue]);

  const calculatePercentage = (currenMonth, lastMonth, totalCount) => {
    let increment = false;
    let percentage = 0;

    const currentMonthPercentage = (currenMonth / totalCount) * 100;

    const lastMonthPercentage = (lastMonth / totalCount) * 100;

    if (currentMonthPercentage >= lastMonthPercentage) {
      increment = true;
      percentage = currentMonthPercentage - lastMonthPercentage;
    } else {
      increment = false;
      percentage = lastMonthPercentage - currentMonthPercentage;
    }

    return { increment, percentage };
  };

  const data = {
    labels: chartLabels,
    text: 'revenue',
    datasets: [
      {
        // data from API
        data: chartData,
        fill: origin,
        borderColor: 'rgb(82,126,60)',
        backgroundColor: 'rgb(82,126,60)',
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Users Gained between 2016-2020',
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },

    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },

          ticks: {
            padding: 30,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
          },

          ticks: {
            padding: 20,
          },
        },
      ],
    },
  };

  return (
    <div className="dashboard-container">
      <SpinnerLoader showSpinner={showSpinner} />
      <div className="text-white ml-3 mb-2">Dashboard</div>
      <div className="d-flex  justify-content-start">
        <Col>
          <DashboardCard
            title={'TOTAL FARMERS'}
            count={getRoleCount('farmer')?.totalCount || 0}
            icon={'/dashboard-icons/Farmer.svg'}
            increment={
              farmerChange.percentage
                ? Number(farmerChange.percentage).toFixed(2)
                : 0
            }
            increased={
              farmerChange.increment
                ? Number(farmerChange.increment).toFixed(2)
                : 0
            }
          />
        </Col>

        <Col>
          <DashboardCard
            title={'TOTAL SUPPLIERS'}
            count={getRoleCount('supplier')?.totalCount || 0}
            icon={'/dashboard-icons/Supplier.svg'}
            increment={
              supplierChange.percentage
                ? Number(supplierChange.percentage).toFixed(2)
                : 0
            }
            increased={
              supplierChange.increment
                ? Number(supplierChange.increment).toFixed(2)
                : 0
            }
          />
        </Col>

        <Col>
          <DashboardCard
            title={'TOTAL TENDERS'}
            count={totalTenders?.totalCount || 0}
            icon={'/dashboard-icons/Tenders.svg'}
            increment={
              tendersChange.percentage
                ? Number(tendersChange.percentage).toFixed(2)
                : 0
            }
            increased={
              tendersChange.increment
                ? Number(tendersChange.increment).toFixed(2)
                : 0
            }
          />
        </Col>

        <Col>
          <DashboardCard
            title={'APPROVED TRANSACTIONS'}
            count={totalRevnue[0]?.totalPrice || 0}
            icon={'/dashboard-icons/Funds.svg'}
            increment={
              revnueChange.percentage
                ? Number(revnueChange.percentage).toFixed(2)
                : 0
            }
            increased={
              supplierChange.increment
                ? Number(supplierChange.increment).toFixed(2)
                : 0
            }
          />
        </Col>
      </div>

      <div className="bg-white chart">
        <div className=" d-flex justify-content-between align-items-center">
          <div className="mx-3 mt-3">
            <p className="overview-text">Overview</p>
            <p className="revnue-text">Revenue</p>
          </div>

          <div className="mx-3">
            {/* <button
              className={`btn ${btnId === 0 ? 'btn-month' : 'btn-week'} `}
              onClick={handleYear}
            >
              Year
            </button> */}

            <button
              className={`btn ${btnId === 1 ? 'btn-month' : 'btn-week'} `}
              onClick={handleMonth}
            >
              Month
            </button>
            <button
              className={`btn ${btnId === 2 ? 'btn-month' : 'btn-week'} `}
              onClick={handleWeek}
            >
              Week
            </button>
          </div>
        </div>

        <div className="line-chart">
          <Line data={data} options={options} height={70} />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
