import {
  authorizedGetCall,
  authorizedPostCall,
  authorizedDeleteCall,
  authorizedPutCall,
} from './APIsService';

export const createtender = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall('/tender/create', body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const removeTender = (deleteTenderId) => {
  return new Promise((resolve, reject) => {
    authorizedDeleteCall(`/tender/delete/${deleteTenderId}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const archiveTender = (id) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall(`/tender/update-archive/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
