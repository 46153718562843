import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import './DatePicker.css';
import moment from 'moment';
import { months } from 'shared/constants';
import { Controller } from 'react-hook-form';
import { generateUniqueId } from 'services/utilities';

import calender from '../../assets/img/icons/Calendar.svg';

const CustomDatePicker = (props) => {
  const datePickerRef = useRef(null);

  const disabledDaysCount = props.duration ? props.duration : '';

  const getMonth = (date) => {
    return moment(date).get('month');
  };

  const closeDatePicker = () => {
    datePickerRef.current.setOpen(false);
  };

  const openDatePicker = () => {
    datePickerRef.current.setOpen(true);
  };

  const shouldDisableDate = (date) => {
    const today = moment();
    const endDate = today.clone().add(disabledDaysCount, 'days');
    return moment(date).isBefore(endDate);
  };

  const renderCustomHeader = ({
    date,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
    return (
      <>
        <div className='datepicker-header p-3'>
          {moment(date).format('DD-MM-YYYY')}
        </div>
        <div className='px-3 d-flex align-items-center justify-content-between'>
          <select
            className='months-dropdown'
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={generateUniqueId()} value={option}>
                {option}
              </option>
            ))}
          </select>
          <div>
            <button
              type='button'
              className='datepicker-btn p-3'
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </button>
            <button
              type='button'
              className='datepicker-btn btn-right'
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </button>
          </div>
        </div>
      </>
    );
  };

  const renderCustomFooter = () => {
    return (
      <div className='custom-datepicker-footer'>
        <button
          type='button'
          className='datepicker-btn float-start'
          onClick={() => props.setValue(props.name, '')}
        >
          Clear
        </button>
        <div className='float-end'>
          <button
            type='button'
            onClick={closeDatePicker}
            className='datepicker-btn'
          >
            Close
          </button>
          <button
            type='button'
            onClick={closeDatePicker}
            className='datepicker-btn btn-right'
          >
            Done
          </button>
        </div>
      </div>
    );
  };

  return (
    <Controller
      control={props.control}
      name={props.name}
      defaultValue={props.range ? ['', ''] : ''}
      rules={{
        validate: (value) => {
          if (props.range) {
            return value[0] && value[1];
          } else {
            return value;
          }
        },
      }}
      render={(params) => (
        <div className='custom-date-picker position-relative'>
          <DatePicker
            required={props.required}
            {...params.field}
            selectsRange={props.range}
            {...(props.range && { startDate: params.field.value[0] })}
            {...(props.range && { endDate: params.field.value[1] })}
            ref={datePickerRef}
            selected={props.range ? params.field.value[0] : params.field.value}
            onChange={(date) => params.field.onChange(date)}
            placeholderText={props.placeholder}
            className={props.className}
            renderCustomHeader={(params) => renderCustomHeader(params)}
            dateFormat='dd-MM-yyyy'
            filterDate={disabledDaysCount && shouldDisableDate}
          >
            {renderCustomFooter()}
          </DatePicker>
          <img
            className='calendar-icon position-absolute'
            src={calender}
            alt='calendar'
            role='button'
            onClick={openDatePicker}
          />
        </div>
      )}
    />
  );
};

export default CustomDatePicker;
