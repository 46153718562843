import React from 'react';
import './SimpleModal.css';
import Modal from 'react-bootstrap/Modal';
import { Button, Col, Row } from 'react-bootstrap';

function SimpleModal({
  show,
  handleClose,
  noClick,
  yesClick,
  message,
  title,
  img,
  primary,
  yesBtnText,
  noBtnText,
  enlarge,
  danger,
}) {
  return (
    <div className="my-modal">
      <Modal className="myModal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="px-2">
            <div className="mb-4 d-flex align-items-center justify-content-center">
              <img
                src={img}
                alt="logout"
                className={enlarge ? 'modal-img-enlarge' : 'modal-img'}
              />
            </div>
            <div className="mb-4">
              <h4 className="text-center">{title}</h4>
              <div className="text-center">{message}</div>
            </div>
            <div className="mb-5 d-flex align-items-center justify-content-center">
              <Row className="w-100">
                <Col xs="6" sm="6">
                  <div className="w-100 d-flex align-items-center justify-content-end">
                    <Button
                      onClick={noClick}
                      className={`me-3 border-btn text-nowrap`}
                    >
                      {noBtnText ? noBtnText : 'No'}
                    </Button>
                  </div>
                </Col>
                <Col xs="6" sm="6">
                  <div className="w-100 d-flex align-items-center justify-content-start">
                    <Button
                      onClick={yesClick}
                      className={`${
                        primary
                          ? 'primary-btn'
                          : danger
                          ? 'danger-filled-btn'
                          : 'blue-btn'
                      } text-nowrap`}
                    >
                      {yesBtnText ? yesBtnText : 'Yes'}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SimpleModal;
