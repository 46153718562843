import React, { useState } from 'react';
import '../listings/Listings.css';
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Row,
  Input,
} from 'reactstrap';
// core components
import { fetchDisputesApi } from 'services/adminService';
import { getDayDifference } from 'services/utilities';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import SpinnerLoader from 'components/Misc/Spinner';
import FilterModal from 'components/Modals/FilterModal';
import DisputeReasonModal from 'components/Modals/DisputeReasonModal';
import { createRoom } from 'services/messagesService';
import { generateUniqueId } from 'services/utilities';
import { Dropdown } from 'react-bootstrap';

function Disputes({ showProfile = false }) {
  const history = useHistory();
  const [disputes, setDisputes] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showSpinner, setSpinner] = useState(true);
  const [pageSize, setPageSize] = useState(30);
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [reasonModal, setReasonModal] = useState(false);
  const [reason, setReason] = useState();
  const [sort, setSort] = useState('');

  React.useEffect(() => {
    fetchDisputes({
      pageNo: currentPage,
      size: pageSize,
      ...(search.trim() && {
        search: search.trim(),
      }),
      ...(filters.fromDate && {
        fromDate: filters.fromDate,
      }),
      ...(filters.toDate && {
        toDate: filters.toDate,
      }),
      ...(filters.zipCode && {
        zipCode: filters.zipCode,
      }),
      ...(filters.range && {
        range: filters.range,
      }),
      ...(filters.status && {
        status: filters.status,
      }),
      sort,
    });
  }, [filters, sort]);

  const fetchDisputes = async (body) => {
    setSpinner(true);
    fetchDisputesApi(body)
      .then((data) => {
        setDisputes(data.data);
        setCount(data.meta.total_count);
        setSpinner(false);
      })
      .catch((err) => console.log(err));
  };

  const pages = () => {
    let pagesArr = [];
    for (let i = 1; i <= Math.ceil(count / pageSize); i++) {
      pagesArr.push(
        <PaginationItem className={currentPage === i ? 'active' : ''}>
          <PaginationLink onClick={(e) => handlePageClick(e, i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return pagesArr;
  };

  const handlePageClick = (e, pageNumber) => {
    e.preventDefault();
    fetchDisputes({
      pageNo: pageNumber,
      size: pageSize,
      ...(search.trim() && {
        search: search.trim(),
      }),
    });
    setPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    fetchDisputes({
      pageNo: currentPage,
      size: pageSize,
      search: e.target.value.trim(),
    });
  };

  const onEntriesSelect = (e) => {
    setPageSize(parseInt(e.target.value));
    fetchDisputes({
      pageNo: currentPage,
      size: pageSize,
      ...(search.trim() && {
        search: search.trim(),
      }),
    });
  };

  const handleFilters = () => {
    setShowModal(!showModal);
  };

  const createChatRoom = async (tender) => {
    const room = await createRoom({
      ids: [
        tender?.user_id,
        tender?.supplier_id,
        localStorage.getItem('user_id'),
      ],
      tenderId: tender?._id,
    });
    history.push(`/admin/messages`, { room: room._id });
  };

  const tableDataHeader = [
    { title: 'USER NAME', width: 15 },
    { title: 'USER NAME', width: 15 },
    { title: 'TENDER NUMBER', width: 10 },
    { title: 'TENDER NAME', width: 10 },
    { title: 'CREATED AT', width: 10 },
    { title: 'DISPUTE DURATION', width: 10 },
    { title: 'TOTAL PRICE', width: 10 },
    { title: 'STATUS', width: 10 },
    { title: 'ACTIONS', width: 10 },
  ];

  return (
    <>
      {reasonModal && (
        <DisputeReasonModal
          title={'Dispute Reason'}
          text={reason}
          handleClose={() => setReasonModal(false)}
          showModal={reasonModal}
        />
      )}
      <div>
        <SpinnerLoader showSpinner={showSpinner} />
        <div
          className={showProfile ? 'profile-container' : 'listing-container'}
        >
          {showModal && (
            <FilterModal
              showModal={showModal}
              handleClose={handleFilters}
              setFilters={setFilters}
              statusId={2}
            />
          )}
          <div className='text-white mb-3'>Disputes</div>
          <div className=''>
            <Row>
              <div className='col'>
                <Card>
                  <CardHeader className='border-0'>
                    {/* card header filter action and tender listing heading */}
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='tender-listing-heading'>Dispute</div>
                      <div>
                        <button
                          className='btn border border-dark'
                          onClick={handleFilters}
                        >
                          <img src='/dashboard-icons/Filters.svg' /> Filters
                        </button>
                        <div className='dropdown'>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant='success'
                              id='dropdown-basic'
                              className='btn btn-white sort-by-btn'
                            >
                              <img
                                src='/images/arrow-down-up.svg'
                                alt='sort'
                                className='icon-small mr-2'
                              />
                              Sort by
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => setSort('days')}>
                                Duration
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => setSort('createdAt')}
                              >
                                Created At
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>

                    {/* Card header 2nd row for page entries and search action */}
                    <div className='mt-3 d-flex align-items-center justify-content-between'>
                      <div className='pageEntry'>
                        <small>
                          Show
                          <select
                            defaultValue={10}
                            onChange={onEntriesSelect}
                            className='pl-1 pr-3 ml-1'
                          >
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                          </select>
                          <i className='fa fa-sort text-xxs select-options'></i>
                          <span className='ml-2'>entires</span>
                        </small>
                      </div>

                      <div className='d-flex align-items-center'>
                        <div className='d-inline-block mr-4 search-color'>
                          Search:
                        </div>
                        <Input
                          className='d-inline-block listing-search-box'
                          placeholder='Search Title'
                          type='text'
                          value={search}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </CardHeader>
                  <div className='table-responsive'>
                    <Table className='dataTable align-items-center'>
                      <thead className='table-head icon-color-light'>
                        <tr>
                          {tableDataHeader.map((header, index) => {
                            return (
                              <th
                                key={generateUniqueId()}
                                className={`px-0 w-${header.width} ${
                                  index === 0 && 'text-left pl-4'
                                }`}
                                scope='col'
                              >
                                {header.title}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody className='list'>
                        {disputes.map((dispute, index) => (
                          <tr
                            key={generateUniqueId()}
                            style={{
                              background:
                                index % 2 == 0 ? '#EDF5EA4D' : 'white',
                            }}
                          >
                            <td className='pl-2'>
                              <div className='overflowStyle d-flex justify-content-start align-items-center farmer-name '>
                                {dispute?.farmerProfileUrl[0] ? (
                                  <img
                                    src={dispute?.farmerProfileUrl[0]}
                                    className='mr-1 profile-pic'
                                  />
                                ) : (
                                  <img
                                    src='/Profle-Image.svg'
                                    className='mr-1 profile-pic'
                                  />
                                )}
                                <div className='d-flex flex-column align-items-start'>
                                  <span className='user-name'>
                                    {dispute?.farmerSide[0]}
                                  </span>
                                  <span>{dispute?.farmerSideRole[0]}</span>
                                </div>
                              </div>
                            </td>

                            <td className='pl-2'>
                              {dispute?.supplierSide?.length ? (
                                <>
                                  <div className='overflowStyle d-flex justify-content-start align-items-center farmer-name '>
                                    {dispute?.supplierProfileUrl ? (
                                      <img
                                        src={dispute?.supplierProfileUrl[0]}
                                        className='mr-1 profile-pic'
                                      />
                                    ) : (
                                      <img
                                        src='/Profle-Image.svg'
                                        className='mr-1 profile-pic'
                                      />
                                    )}
                                    <div className='d-flex flex-column align-items-start'>
                                      <span className='user-name'>
                                        {dispute?.supplierSide[0]}
                                      </span>
                                      <span>
                                        {dispute?.supplierSideRole[0]}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>N/A</>
                              )}
                            </td>

                            <td className='text-dark overflowStyle px-1'>
                              #{dispute?._id}
                            </td>
                            <td className='text-dark overflowStyle px-1'>
                              {dispute.title}
                            </td>
                            <td className='px-1'>
                              {moment(dispute.createdAt)
                                .utc()
                                .format('DD-MM-YYYY')}
                            </td>
                            <td className='overflowStyle px-1'>
                              {getDayDifference(
                                dispute?.disputes?.startDate,
                                dispute?.disputes?.endDate
                              ) || 0}{' '}
                              days
                            </td>
                            <td className='overflowStyle px-1'>
                              ${dispute?.price[0]}
                            </td>
                            <td className='px-1'>
                              <div className='d-flex justify-content-center align-items-center'>
                                {dispute?.disputes?.isClosed ? (
                                  <>
                                    <span
                                      style={{
                                        backgroundColor: 'blue',
                                      }}
                                      className='tender-status-dot '
                                    ></span>
                                    Resolved
                                  </>
                                ) : (
                                  <>
                                    <span
                                      style={{
                                        backgroundColor: 'red',
                                      }}
                                      className='tender-status-dot'
                                    ></span>
                                    Disputed
                                  </>
                                )}
                              </div>
                            </td>
                            <td className='pr-2'>
                              <div>
                                <img
                                  className='tendersImageTable'
                                  alt='...'
                                  src='/dashboard-icons/View.svg'
                                  onClick={() => {
                                    history.push(`listing/${dispute?._id}`);
                                  }}
                                  role={'button'}
                                />
                                {getDayDifference(
                                  dispute?.disputes?.startDate,
                                  dispute?.disputes?.endDate
                                ) >= 7 ? (
                                  <img
                                    className='tendersImageTable mx-1'
                                    alt='...'
                                    src='/dashboard-icons/Chat-Enabled.svg'
                                    onClick={() => createChatRoom(dispute)}
                                    role={'button'}
                                  />
                                ) : (
                                  <img
                                    className='tendersImageTable mx-1'
                                    alt='...'
                                    src='/dashboard-icons/Chat-Disabled.svg'
                                    role={'button'}
                                  />
                                )}
                                {!dispute?.disputes?.isClosed ? (
                                  <img
                                    className='tendersImageTable'
                                    alt='...'
                                    src='/dashboard-icons/Question-enabled.svg'
                                    onClick={() => {
                                      setReason(dispute?.disputes?.reason);
                                      setReasonModal(!reasonModal);
                                    }}
                                    role={'button'}
                                  />
                                ) : (
                                  <img
                                    className='tendersImageTable'
                                    alt='...'
                                    src='/dashboard-icons/question-disabled.svg'
                                    role={'button'}
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {!showSpinner && !disputes.length && (
                      <div className='d-flex align-items-center justify-content-center mb-3'>
                        No records found
                      </div>
                    )}
                  </div>
                  <CardFooter className=''>
                    <Pagination
                      className='pagination justify-content-end mb-0'
                      listClassName='justify-content-end mb-0'
                    >
                      <PaginationItem
                        className={currentPage === 1 ? 'disabled' : ''}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, currentPage - 1)}
                          tabIndex='-1'
                        >
                          <i className='fas fa-angle-left' />
                          <span className='sr-only'>Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      {pages().map((page) => (
                        <div key={generateUniqueId()}>{page}</div>
                      ))}
                      <PaginationItem
                        className={
                          currentPage >= Math.ceil(count / pageSize)
                            ? 'disabled'
                            : ''
                        }
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, currentPage + 1)}
                        >
                          <i className='fas fa-angle-right' />
                          <span className='sr-only'>Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default Disputes;
