import React, { useState } from 'react';
import './Listings.css';
import TenderDetail from 'views/tender-detail/TenderDetail';
import Quotes from 'views/quotes/Quotes';
import Hired from 'views/hired/Hired';
import QuoteDetail from 'views/quote-detail/QuoteDetail';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import DeleteModal from 'components/Modals/deleteModal';
import SpinnerLoader from 'components/Misc/Spinner';
import { removeTender } from 'services/tenderService';

function Listing() {
  const { id } = useParams();
  const location = useLocation();
  const [buttonId, setButtonId] = useState(1);
  const noOfQuotes = location?.state?.quotesLength;
  const [quoteId, setQuoteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const history = useHistory();

  const handleClick = (id) => {
    setButtonId(id);
  };

  const handleQuoteId = (id) => {
    setQuoteId(id);
  };

  const deleteTender = () => {
    console.log(id);
    setSpinner(true);
    removeTender(id)
      .then(() => {
        setSpinner(false);
        setDeleteModal(false);
        history.push('/admin/listings');
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <DeleteModal
        title="Delete Tender"
        message="Are you sure to delete Tender?"
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleDelete={deleteTender}
      />

      <div className="listing-container mt-5">
        {/* listing details, quotes and hired title */}
        <div className="listing-title d-flex justify-content-between align-items-center ">
          <div className="d-flex">
            <div className="text-white mb-4">
              Listing -{' '}
              <span style={{ opacity: buttonId == 1 ? '0.5' : '1' }}>
                Listing Detail
              </span>
            </div>
            {buttonId === 2 && (
              <span
                className="text-white"
                style={{ opacity: buttonId == 2 ? '0.5' : '1' }}
              >
                &nbsp;- &nbsp;Quote Listing
              </span>
            )}
            {buttonId === 3 && (
              <span className="text-white">
                &nbsp; -&nbsp;Quote Listing{' '}
                <span style={{ opacity: buttonId == 3 ? '0.5' : '1' }}>
                  {' '}
                  -Quote Details
                </span>
              </span>
            )}
            {buttonId === 4 && (
              <span className="text-white">
                &nbsp; -&nbsp;Quote Listing&nbsp;-&nbsp;Quote Details{' '}
                <span style={{ opacity: buttonId == 4 ? '0.5' : '1' }}>
                  -Hired{' '}
                </span>
              </span>
            )}
          </div>
          {buttonId === 1 && (
            <button
              className="btn btn-delete"
              onClick={() => setDeleteModal(true)}
            >
              Delete Tender
            </button>
          )}
        </div>

        {/* Action buttons changes button id to render different component */}
        <div className="listing-detail-actions  mb-4">
          <button
            className="btn visited fw-bold"
            onClick={() => handleClick(1)}
            style={{
              color: buttonId === 1 ? '#5b8546' : 'white',
              background: buttonId === 1 ? 'white' : '#5b8546',
            }}
          >
            Tender Details
          </button>

          <button
            className="btn visited"
            onClick={() => handleClick(2)}
            style={{
              color: buttonId === 2 ? '#5b8546' : 'white',
              background: buttonId === 2 ? 'white' : '#5b8546',
            }}
          >
            Quotes {noOfQuotes !== 0 && `(${noOfQuotes})`}
          </button>

          <button
            className="btn visited"
            onClick={() => handleClick(4)}
            style={{
              color: buttonId === 4 ? '#5b8546' : 'white',
              background: buttonId === 4 ? 'white' : '#5b8546',
            }}
          >
            Hired
          </button>
        </div>

        {/* render component on base of button id */}
        <div className="mt-3 render-container">
          {buttonId === 1 && <TenderDetail id={id} />}
          {buttonId === 2 && (
            <Quotes
              id={id}
              title={location?.state?.title}
              handleClick={handleClick}
              handleQuoteId={handleQuoteId}
            />
          )}
          {buttonId === 3 && <QuoteDetail quoteId={quoteId} />}
          {buttonId === 4 && <Hired quoteId={id} />}
        </div>
      </div>
    </>
  );
}

export default Listing;
