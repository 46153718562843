import React, { useEffect } from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import {
  validateResetLink,
  changePasswordWithLink,
} from "services/authService";
import { useHistory, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { errorToast, successToast } from "shared/constants";
import SpinnerLoader from "components/Misc/Spinner";
import passwordLockIcon from "../../assets/img/icons/Password Lock.svg"

function ResetPassword() {
  const history = useHistory();
  const { id } = useParams();
  console.log(id)
  useEffect(() => {
    id ? checkResetLink() : setSpinner(false);
  }, []);

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showSpinner, setSpinner] = React.useState(true);
  const [validLink, setValidLink] = React.useState(false);

  const checkResetLink = () => {
    validateResetLink(id)
      .then((data) => {
        data.message
          ? setValidLink(true)
          : toast.error("Link not Valid", errorToast);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        toast.error("Something went wrong", errorToast);
      });
  };

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not Match!", errorToast);
      return;
    }
    if (password.length < 6) {
      toast.error("Passwords must be atleast 6 characters", errorToast);
      return;
    }
    setSpinner(true);
    changePasswordWithLink(password, id)
      .then((data) => {
        !data.message
          ? toast.error("Password Reset Failed", errorToast)
          : toast.success("Password reset Successfully!", successToast);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        toast.error("Something went wrong", errorToast);
      });
  };

  return (
    <>
      <SpinnerLoader adminView={true} showSpinner={showSpinner} />
      <AuthHeader />
      <div className="loginForm">
        <Container className="mt-n18 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-default-login border-0 mb-0">
                <CardBody className="px-lg-6 py-lg-3">
                  <div className="d-flex justify-content-start">
                    <img
                      src={passwordLockIcon}
                      className="bh_logo"
                      alt="logo"
                    />
                  </div>
                  <div className="text-muted mt-4 mb-4">
                    <h1 className="black-color">Create new password</h1>
                    <p className="black-color">
                      That's it. Change your password
                    </p>
                  </div>
                  <Form role="form">
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          disabled={!validLink}
                          placeholder="Enter Password"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          disabled={!validLink}
                          placeholder="Confirm new Password"
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="default-button-background my-2"
                        color="info"
                        type="button"
                        onClick={handleSubmit}
                      >
                        Reset Password
                      </Button>
                    </div>

                    {/* <div className="d-flex align-items-center justify-content-center mb-4">
                      <small
                        onClick={(e) => history.push("/auth/login")}
                        className="success-color"
                        role={"button"}
                      >
                        Back to Login
                      </small>
                    </div> */}
                    
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Toaster />
    </>
  );
}

export default ResetPassword;
