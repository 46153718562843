import React, { useEffect, useState } from 'react';
import './tenderDetail.css';
import AttachmentCard from 'components/cards/dashboard-card/attachement-card/AttachmentCard';
import { getOneTender } from '../../services/adminService';
import {
  handleDownload,
  statusColor,
  getDayDifference,
  getTenderID,
  generateUniqueId,
} from '../../services/utilities';
import moment from 'moment';
import SpinnerLoader from 'components/Misc/Spinner';
import { Col, Row } from 'reactstrap';

const TenderDetail = (props) => {
  const [tender, setTender] = useState({});
  const [showSpinner, setSpinner] = useState(true);
  const [days, setDays] = useState();

  useEffect(() => {
    getOneTender(props.id)
      .then((data) => {
        setTender(data);
        setSpinner(false);
        setDays(getDayDifference(data.duration[1], data.duration[0]));
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className='tender-details px-3 py-4'>
      <SpinnerLoader showSpinner={showSpinner} />
      <div className='tender-details-heading border-bottom'>Tender Details</div>

      {/* tender info */}
      <Row className='mt-4'>
        <Col md='2'>
          <div className='title-text'>Submitted By</div>
          <div className='title-value d-flex'>
            {/* <img src="" alt="profile" /> */}
            <div className='title-vale'>{tender.username}</div>
          </div>
        </Col>

        <Col md='2'>
          <div className='title-text'>Tender Number</div>
          <div className='title-value'>#{getTenderID(tender._id)}</div>
        </Col>

        <Col md='2'>
          <div className='title-text'>Tender Name</div>
          <div className='title-value'>{tender.title}</div>
        </Col>

        <Col md='2'>
          <div className='title-text'>Created At</div>
          <div className='title-value'>
            {moment(tender.createdAt).utc().format('DD-MM-YYYY')}
          </div>
        </Col>

        <Col md='2'>
          <div className='title-text'>Tender Duration</div>
          <div className='title-value'>{days} days</div>
        </Col>

        <Col md='2'>
          <span
            style={{
              color: statusColor(tender.status) || 'blue',
            }}
            className='tender-detail-dot'
          >
            .
          </span>
          <span>{tender.status}</span>
        </Col>
      </Row>

      {/* shipping info */}
      <Row className='mt-4'>
        <Col md='2'>
          <div className='title-text'>Town Closest to Farm</div>
          <div className='title-value'>{tender.post_code}</div>
        </Col>
        <Col md='2'>
          <div className='title-text'>Transport Option</div>
          <div className='title-value'>
            {tender?.transport === 'Delivery'
              ? 'Delivered to Farm'
              : tender?.transport}
          </div>
        </Col>
        {tender?.transport === 'Delivery' ? (
          <Col md='2'>
            <div className='title-text'>Farm Distance from Town Center</div>
            <div className='title-value'>{tender?.farm_distance || 'N/A'}</div>
          </Col>
        ) : (
          <Col md='2'>
            <div className='title-text'>Pickup Radius from Town Centre</div>
            <div className='title-value'>{tender?.pickup_radius || 'N/A'}</div>
          </Col>
        )}

        <Col md='2'>
          <div className='title-text'>Payment Option</div>
          <div className='title-value'>{tender?.payment_option || 'N/A'}</div>
        </Col>

        <Col md='2'>
          <div className='title-text'>Payment Method</div>
          <div className='title-value'>{tender?.payment_mode || 'N/A'}</div>
        </Col>

        <Col md='2'>
          <div className='title-text'>{`${tender?.transport} Date`}</div>
          <div className='title-value'>
            {tender?.completion_date
              ? moment(tender?.completion_date).format('DD-MM-YYYY')
              : 'N/A'}
          </div>
        </Col>
      </Row>

      {/* tender details info */}
      <div className='tender-order-details my-3'>
        <div className='title-text'>Order Details</div>
        <div className='title-value'>{tender.details || 'N/A'}</div>
      </div>

      {/* extra shipping instructions */}
      <div className='extra-shipping-instructions my-3'>
        <div className='title-text'>Extra Shipping Instruction</div>
        <div className='title-value'>{tender.extra_info || 'N/A'}</div>
      </div>

      {tender?.attachments?.length ? (
        <div className='mt-3'>
          <div className='d-flex align-items-center justify-content-between mb-3'>
            <div className='title-text'>Attachments</div>
            <div
              onClick={() => handleDownload(setSpinner, tender?.attachments)}
              role='button'
              className='primary-color'
            >
              <span style={{ color: '#527e3c' }}>Download all</span>
              <img
                className='download-icon ms-2 mb-2'
                src={'/Download.svg'}
                alt='download'
              />
            </div>
          </div>

          <div className='d-flex flex-wrap'>
            {tender?.attachments.map((attachment) => (
              <AttachmentCard
                key={generateUniqueId()}
                attachment={attachment}
                setSpinner={setSpinner}
              />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TenderDetail;
