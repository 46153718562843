import React, { useState } from 'react';
import './Listings.css';
import actionImg from '../../assets/img/icons/action.svg';
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Row,
  Input,
} from 'reactstrap';
// core components
import { fetchTenders } from 'services/adminService';
import { getDayDifference, statusColor } from 'services/utilities';
import moment from 'moment';
import SpinnerLoader from 'components/Misc/Spinner';
import FilterModal from 'components/Modals/FilterModal';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { getTenderID } from 'services/utilities';
import { IMAGES_ASSESTS_PATH } from 'shared/constants';
import { generateUniqueId } from 'services/utilities';
import { removeTender, archiveTender } from 'services/tenderService';
import { useHistory } from 'react-router-dom';
import DeleteModal from 'components/Modals/deleteModal';
import ArchiveModal from 'components/Modals/archiveModal';
import { Dropdown } from 'react-bootstrap';
import editIcon from '../../assets/img/icons/Edit.svg';

function Listings({ showProfile = false, userId }) {
  const history = useHistory();
  const [tenders, setTenders] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showSpinner, setSpinner] = useState(true);
  const [pageSize, setPageSize] = useState(30);
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [deleteTenderId, setDeleteTenderId] = useState('');
  const [archiveId, setArchiveId] = useState('');
  const [editTenderId, setEditTenderId] = useState('');
  const [sort, setSort] = useState('');
  const [isArchived, setIsArchived] = useState(false);

  React.useEffect(() => {
    fetchTender({
      pageNo: currentPage,
      size: pageSize,
      ...(search.trim() && {
        search: search.trim(),
      }),
      ...(filters.fromDate && {
        fromDate: filters.fromDate,
      }),
      ...(filters.toDate && {
        toDate: filters.toDate,
      }),
      ...(filters.zipCode && {
        zipCode: filters.zipCode,
      }),
      ...(filters.range && {
        range: filters.range,
      }),
      ...(filters.status && {
        status: filters.status,
      }),
      ...(userId && {
        userId: userId,
      }),
      sort,
    });
  }, [filters, deleteModal, sort, archiveModal]);

  const fetchTender = async (body) => {
    setSpinner(true);
    fetchTenders(body)
      .then((data) => {
        setTenders(data.data);
        setCount(data.meta.total_count);
        setSpinner(false);
      })
      .catch((err) => console.log(err));
  };

  const pages = () => {
    let pagesArr = [];
    for (let i = 1; i <= Math.ceil(count / pageSize); i++) {
      pagesArr.push(
        <PaginationItem className={currentPage === i ? 'active' : ''}>
          <PaginationLink onClick={(e) => handlePageClick(e, i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return pagesArr;
  };

  const handlePageClick = (e, pageNumber) => {
    e.preventDefault();
    fetchTender({
      pageNo: pageNumber,
      size: pageSize,
      ...(search.trim() && {
        search: search.trim(),
      }),
    });
    setPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    fetchTender({
      pageNo: currentPage,
      size: pageSize,
      search: e.target.value.trim(),
    });
  };

  const onEntriesSelect = (e) => {
    setPageSize(parseInt(e.target.value));
    fetchTender({
      pageNo: currentPage,
      size: pageSize,
      ...(search.trim() && {
        search: search.trim(),
      }),
    });
  };

  const handleFilters = () => {
    setShowModal(!showModal);
  };

  const deleteTender = () => {
    setSpinner(true);
    removeTender(deleteTenderId)
      .then(() => {
        setSpinner(false);
        setDeleteModal(false);
      })
      .catch((error) => {
        setSpinner(false);
        console.log(error);
      });
  };

  const archiveTenderWrapper = () => {
    setSpinner(true);
    archiveTender(archiveId)
      .then(() => {
        setSpinner(false);
        setArchiveModal(false);
      })
      .catch((error) => {
        setSpinner(false);
        console.log(error);
      });
  };

  const tableDataHeader = [
    { title: 'FARMER NAME', width: 10 },
    { title: '#TENDER', width: 5 },
    { title: 'TENDER NAME', width: 10 },
    { title: 'CREATED AT', width: 8 },
    { title: 'TENDER DAYS LEFT', width: 7 },
    { title: 'POST CODE', width: 15 },
    { title: 'QUOTE RECEIVED', width: 17 },
    { title: 'STATUS', width: 8 },
    { title: 'ACTIONS', width: 10 },
  ];

  return (
    <>
      <DeleteModal
        title='Delete Tender'
        message='Are you sure to delete Tender?'
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleDelete={deleteTender}
      />

      <ArchiveModal
        title='Archive Tender'
        message={
          isArchived
            ? 'Are you sure to Unarchive Tender?'
            : 'Are you sure to Archive Tender?'
        }
        open={archiveModal}
        handleClose={() => setArchiveModal(false)}
        handleArchive={archiveTenderWrapper}
      />

      <div>
        <SpinnerLoader showSpinner={showSpinner} />
        <div
          className={showProfile ? 'profile-container' : 'listing-container'}
        >
          {showModal && (
            <FilterModal
              showModal={showModal}
              handleClose={handleFilters}
              setFilters={setFilters}
              statusId={1}
            />
          )}
          <div className='text-white mb-3'>Listings</div>
          <div className=''>
            <Row>
              <div className='col'>
                <Card>
                  <CardHeader className='border-0'>
                    {/* card header filter action and tender listing heading */}
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='tender-listing-heading'>
                        Tender Listings
                      </div>
                      <div>
                        <button
                          className='btn border border-dark'
                          onClick={handleFilters}
                        >
                          <img src='/dashboard-icons/Filters.svg' /> Filters
                        </button>
                        <div className='dropdown'>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant='success'
                              id='dropdown-basic'
                              className='btn btn-white sort-by-btn'
                            >
                              <img
                                src='/images/arrow-down-up.svg'
                                alt='sort'
                                className='icon-small mr-2'
                              />
                              Sort by
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => setSort('days')}>
                                Duration
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => setSort('createdAt')}
                              >
                                Created At
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>

                    {/* Card header 2nd row for page entries and search action */}
                    <div className='mt-3 d-flex align-items-center justify-content-between'>
                      <div className='pageEntry'>
                        <small>
                          Show
                          <select
                            defaultValue={10}
                            onChange={onEntriesSelect}
                            className='pl-1 pr-3 ml-1'
                          >
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                          </select>
                          <i
                            role='button'
                            className='fa fa-sort text-xxs select-options'
                          ></i>
                          <span className='ml-2'>entries</span>
                        </small>
                      </div>

                      <div className='d-flex align-items-center'>
                        <div className='d-inline-block mr-4 search-color'>
                          Search:
                        </div>
                        <Input
                          className='d-inline-block listing-search-box'
                          placeholder='Search Title'
                          type='text'
                          value={search}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </CardHeader>
                  <div className='table-responsive'>
                    <Table className='dataTable align-items-center'>
                      <thead className='table-head icon-color-light'>
                        <tr>
                          {tableDataHeader.map((header, index) => {
                            return (
                              <th
                                key={generateUniqueId()}
                                className={`px-0 w-${header.width} ${
                                  index === 0 && 'text-left pl-4'
                                }`}
                                scope='col'
                              >
                                {header.title}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody className='list'>
                        {tenders.map((tender, index) => (
                          <tr
                            key={generateUniqueId()}
                            style={{
                              background:
                                index % 2 == 0 ? '#EDF5EA4D' : 'white',
                            }}
                          >
                            <td className='pl-3'>
                              <div className='overflowStyle d-flex justify-content-start align-items-center farmer-name'>
                                <img
                                  src={
                                    tender?.user?.profileUrl
                                      ? tender?.user?.profileUrl
                                      : `${IMAGES_ASSESTS_PATH}/Profle-Image.svg`
                                  }
                                  className='mr-2 small-icon profile-pic'
                                />
                                {tender.user.name}
                              </div>
                            </td>
                            <td className='text-dark overflowStyle px-1'>
                              #{getTenderID(tender._id)}
                            </td>
                            <td className='text-dark overflowStyle px-1'>
                              {tender.title}
                            </td>
                            <td className='px-1'>
                              {moment(tender.createdAt)
                                .utc()
                                .format('DD-MM-YYYY')}
                            </td>
                            <td className='overflowStyle px-1'>
                              {getDayDifference(
                                tender.duration[1],
                                tender.duration[0]
                              ) || 0}{' '}
                              days
                            </td>
                            <td className='overflowStyle px-1'>
                              {tender.post_code}
                            </td>
                            <td className='px-1'>
                              <div className='d-flex align-items-center justify-content-center'>
                                {tender.quote_users.slice(0, 2).map(() => {
                                  return (
                                    <img
                                      key={generateUniqueId()}
                                      src={`${IMAGES_ASSESTS_PATH}/Profle-Image.svg`}
                                      alt='profile'
                                      className='small-icon-overlap'
                                    />
                                  );
                                })}
                                {tender.quote_users.length - 3 > 0 && (
                                  <div className='d-inline-block small-div-overlap'>
                                    <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                                      {tender.quote_users.length - 3}+
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className='px-1'>
                              <div className='d-flex justify-content-center align-items-center'>
                                <span
                                  style={{
                                    backgroundColor: !tender?.is_archive
                                      ? statusColor(tender.status)
                                      : 'red' || 'blue',
                                  }}
                                  className='tender-status-dot'
                                ></span>
                                <span className=''>
                                  {tender?.is_archive
                                    ? 'Archive'
                                    : tender.status.toString()}
                                </span>
                              </div>
                            </td>
                            <td>
                              <UncontrolledDropdown className='menu-position'>
                                <DropdownToggle
                                  className='btn-icon-only '
                                  color=''
                                  role='button'
                                  size='sm'
                                >
                                  <img src={actionImg} alt='action' />
                                </DropdownToggle>
                                <DropdownMenu
                                  className='dropdown-menu-arrow'
                                  end
                                >
                                  <DropdownItem
                                    onClick={async () => {
                                      history.push(
                                        `tender-update/${tender?._id}`
                                      );
                                    }}
                                  >
                                    <div className='d-flex align-items-center justify-content-start '>
                                      Edit
                                    </div>
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setDeleteTenderId(tender._id);
                                      setDeleteModal(true);
                                    }}
                                  >
                                    <div className='d-flex align-items-center justify-content-start '>
                                      Delete
                                    </div>
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      history.push(`listing/${tender?._id}`, {
                                        title: tender?.title,
                                        quotesLength:
                                          tender?.quote_users?.length,
                                      });
                                    }}
                                  >
                                    <div className='d-flex align-items-center justify-content-start '>
                                      View
                                    </div>
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setArchiveId(tender._id);
                                      setArchiveModal(true);
                                      setIsArchived(tender.is_archive);
                                    }}
                                  >
                                    <div className='d-flex align-items-center justify-content-start '>
                                      {tender?.is_archive
                                        ? 'Unarchive'
                                        : 'Archive'}
                                    </div>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))}
                        <tr></tr>
                      </tbody>
                    </Table>
                    {!showSpinner && !tenders.length && (
                      <div className='d-flex align-items-center justify-content-center mb-3'>
                        No records found
                      </div>
                    )}
                  </div>
                  <CardFooter className=''>
                    <Pagination
                      className='pagination justify-content-end mb-0'
                      listClassName='justify-content-end mb-0'
                    >
                      <PaginationItem
                        className={currentPage === 1 ? 'disabled' : ''}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, currentPage - 1)}
                          tabIndex='-1'
                        >
                          <i className='fas fa-angle-left' />
                          <span className='sr-only'>Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      {pages().map((page) => (
                        <div key={generateUniqueId()}>{page}</div>
                      ))}
                      <PaginationItem
                        className={
                          currentPage >= Math.ceil(count / pageSize)
                            ? 'disabled'
                            : ''
                        }
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, currentPage + 1)}
                        >
                          <i className='fas fa-angle-right' />
                          <span className='sr-only'>Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default Listings;
