import { authorizedPostCall, authorizedPutCall } from './APIsService';

export const fetchNotifications = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/notification/all`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateNotification = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall(`/notification/${body._id}`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const markRead = () => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/notification/mark-read`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
