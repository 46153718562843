import React, { useEffect, useState } from 'react';
import generator from 'generate-password';
import { changePasswordApi } from 'services/adminService';
import PasswordUpdatedModal from 'components/Modals/PasswordUpdatedModal';
import SpinnerLoader from 'components/Misc/Spinner';
import { checkPassChars } from 'shared/constants';
import { checkPassSpecialChars } from 'shared/constants';
import { checkPassCase } from 'shared/constants';
import { passwordRegex } from 'shared/constants';

const ChangePassword = ({ user }) => {
  const [range, setRange] = useState(8);
  const [generatedPassword, setGeneratedPassword] = useState('');
  const [password, setPassword] = useState('');
  const [finalPassword, setFinalPAssword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [showSpinner, setSpinner] = useState(false);

  const generatePassword = () => {
    const pswd = generator.generate({
      length: range,
      uppercase: true,
      lowercase: true,
      numbers: true,
      symbols: '@#$!%*?&-',
      strict: true,
    });
    setGeneratedPassword(pswd);
  };

  useEffect(() => {
    setIsValid(validatePassword(password));
  }, [password]);

  const changePassword = () => {
    if (isValid) {
      setSpinner(true);
      setFinalPAssword(password);
      changePasswordApi({
        userId: user?._id,
        newPassword: password,
      })
        .then(() => {
          setSpinner(false);
          setPasswordModal(true);
        })
        .catch((error) => console.log(error));
    }
  };

  const changeGeneratedPassword = () => {
    if (generatedPassword) {
      setSpinner(true);
      setFinalPAssword(generatedPassword);
      changePasswordApi({
        userId: user?._id,
        newPassword: generatedPassword,
      })
        .then(() => {
          setSpinner(false);
          setPasswordModal(true);
        })
        .catch((error) => console.log(error));
    }
  };

  const copyPassword = async () => {
    if (generatedPassword) {
      await navigator.clipboard.writeText(generatedPassword);
      alert('password copied to clipboard!');
    }
  };

  const validatePassword = (value) => {
    return passwordRegex.test(value);
  };

  const handleClose = () => {
    setPasswordModal(!passwordModal);
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      {passwordModal && (
        <PasswordUpdatedModal
          email={user?.email}
          password={finalPassword}
          showModal={passwordModal}
          handleClose={handleClose}
        />
      )}
      <div className="password-container ">
        <div className="text-white">
          User Profile -{' '}
          {user?.role.charAt(0).toUpperCase() + user?.role.slice(1)} &nbsp;
          Profile - &nbsp;{' '}
          <span style={{ opacity: '0.5' }}>Update Password</span>
        </div>
        <div className="change-password-container my-3">
          <div className="password-label p-2 my-3 text-bold">
            Change Password
          </div>
          <div className="border horizontal-line w-100"></div>
          <div className="d-flex justify-content-between mt-3 mx-2">
            <div className="border p-3 password-left-div my-3">
              <div className="password-label">Set Password</div>
              <div className="border horizontal-line w-100 my-3"></div>

              {/* left section */}
              <div className="my-2">
                <label htmlFor="" className="password-label">
                  Password
                </label>
                <input
                  type="text"
                  placeholder="Enter new password"
                  className="form-control"
                  defaultValue={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {!isValid && password && (
                  <small style={{ color: 'red' }}>
                    Password must contain one special character, one number and
                    minmium length of 8.
                  </small>
                )}
              </div>
              <div>
                <img
                  src={
                    checkPassChars(password, password)
                      ? '/dashboard-icons/tick-green.svg'
                      : '/dashboard-icons/Tick.svg'
                  }
                  alt=""
                />
                <span className="password-text">Must be 8 Characters</span>
              </div>
              <div>
                <img
                  src={
                    checkPassSpecialChars(password, password)
                      ? '/dashboard-icons/tick-green.svg'
                      : '/dashboard-icons/Tick.svg'
                  }
                  alt=""
                />
                <span className="password-text">One special character</span>
              </div>
              <div>
                <img
                  src={
                    checkPassCase(password, password)
                      ? '/dashboard-icons/tick-green.svg'
                      : '/dashboard-icons/Tick.svg'
                  }
                  alt=""
                />
                <span className="password-text">Atleast One uppercase</span>
              </div>
              <div>
                <button
                  className="btn change-btn my-3"
                  onClick={changePassword}
                >
                  Change Password
                </button>
              </div>
            </div>

            {/* center section */}
            <div className="password-center-div d-flex justify-content-between my-3">
              <div className="border horizontal-line w-50 my-3 mx-3"></div>
              <div className="password-label mx-3"> OR</div>
              <div className="border horizontal-line w-50 my-3 mx-3"></div>
            </div>

            {/* right section */}
            <div className="border p-3 my-3 password-right-div">
              <div className="password-label">Generate Random Password</div>
              <div className="border horizontal-line w-100 my-3"></div>

              <div className="password-label my-2">Passowrd Length</div>
              <div className="range d-flex justify-content-between">
                <input
                  type="range"
                  className="form-range w-75"
                  min="8"
                  max="12"
                  id="customRange2"
                  value={range}
                  onChange={(e) => setRange(e.target.value)}
                />
                <button className="btn border">{range}</button>
              </div>
              <div className="d-flex my-3">
                <input
                  className="form-control"
                  type="text"
                  value={generatedPassword}
                  id=""
                  placeholder={generatedPassword}
                />
                <img
                  src="/dashboard-icons/Copy.svg"
                  alt=""
                  className="copy-img"
                  role="button"
                  onClick={copyPassword}
                />
              </div>

              <div>
                <img
                  src={
                    checkPassChars(generatedPassword, generatedPassword)
                      ? '/dashboard-icons/tick-green.svg'
                      : '/dashboard-icons/Tick.svg'
                  }
                  alt=""
                />
                <span className="password-text">Must be 8 Characters</span>
              </div>
              <div>
                <img
                  src={
                    checkPassSpecialChars(generatedPassword, generatedPassword)
                      ? '/dashboard-icons/tick-green.svg'
                      : '/dashboard-icons/Tick.svg'
                  }
                  alt=""
                />
                <span className="password-text">One special character</span>
              </div>
              <div>
                <img
                  src={
                    checkPassCase(generatedPassword, generatedPassword)
                      ? '/dashboard-icons/tick-green.svg'
                      : '/dashboard-icons/Tick.svg'
                  }
                  alt=""
                />
                <span className="password-text">Atleast One uppercase</span>
              </div>

              <div className="mt-3 d-flex justify-content-between mx-5">
                <button
                  className="btn btn-lg generate-btn"
                  onClick={generatePassword}
                >
                  Generate Password
                </button>
                <button
                  className="btn btn-lg change-btn"
                  onClick={changeGeneratedPassword}
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
