import React, { useState, useEffect } from 'react';
import './quoteInvoice.css';
import { Container } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { getInvoiceDetail } from 'services/adminService';
import TwoButtonModal from 'components/Modals/TwoButtonModal';
import { markPaidApi } from 'services/adminService';
import { getTenderID } from 'services/utilities';
import SpinnerLoader from 'components/Misc/Spinner';
import { PAYMENT_OPTIONS } from 'shared/constants';
import { updatequote } from 'services/adminService';
import { Col, Row } from 'reactstrap';
import AGLogo from '../../../assets/img/icons/Logo.png';

const QuoteInvoice = () => {
  const location = useLocation();
  const { id } = useParams();
  const [invoice, setInvoice] = useState();
  const [price, setPrice] = useState();
  const [showSpinner, setSpinner] = useState(false);
  const [paidModal, setPaidModal] = useState(false);
  const [receivedModal, setReceivedModal] = useState(false);
  const [paidBtn, setPaidBtn] = useState(false);

  useEffect(() => {
    setSpinner(true);
    getInvoiceDetail(id).then((data) => {
      setPrice(data?.transactionAmount || data?.price);
      setInvoice(data);
      setSpinner(false);
    });
  }, []);

  const printDocument = () => {
    setSpinner(true);
    const input = document.getElementById('divToPrint');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({ unit: 'px' });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('invoice.pdf');
      setSpinner(false);
    });
  };

  const markPaid = () => {
    setSpinner(true);
    markPaidApi(id)
      .then(() => {
        setPaidModal(false);
        setPaidBtn(true);
        setSpinner(false);
        setReceivedModal(!receivedModal);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => {
    setPaidModal(false);
    setReceivedModal(false);
  };

  useEffect(() => {
    location.state?.download && invoice && printDocument();
  }, [invoice, location.state?.download]);

  const AgInfo = {
    name: 'iinputs.com',
    ABN: '17666406757',
    address: '39 High Street Kiimba, SA 5641',
    BSB: '015462',
    account: '331525813',
  };

  const getToInfo = () => invoice?.supplier[0];

  const getFromInfo = () => AgInfo;

  const getAmount = () => {
    const totalPrice = price || invoice?.transactionAmount;
    return totalPrice * 0.005;
  };

  useEffect(() => {
    invoice?._id &&
      setTimeout(() => {
        updatequote({
          _id: invoice?._id,
          transactionAmount: price,
        });
      }, 2000);
  }, [price]);

  const changePrice = (e) => {
    setPrice(e.target.value);
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      {paidModal && (
        <TwoButtonModal
          icon={'/dashboard-icons/Question.svg'}
          title={'Approve Invoice'}
          text={'Are you sure you want to approve this invoice?'}
          btn1={'No'}
          btn2={'Yes'}
          handleClick1={() => setPaidModal(!paidModal)}
          handleClick2={markPaid}
          handleClose={() => setPaidModal(!paidModal)}
          showModal={paidModal}
          backgroundColor={'#527e3c'}
        />
      )}
      {receivedModal && (
        <TwoButtonModal
          icon={'/dashboard-icons/Success.svg'}
          title={'Marked Received?'}
          text={'Your Invoice has successfully been marked  as received'}
          btn2={'Close'}
          handleClick2={() => setReceivedModal(!receivedModal)}
          handleClose={handleClose}
          showModal={receivedModal}
          backgroundColor={'#527e3c'}
        />
      )}
      <div className="invoice-container">
        <Container className="mt-4 ">
          <div className="flex-view">
            <div className="flex-view">
              <div className="grey-text">Billing History &gt; &nbsp;</div>
              <div className="black-text">View Invoice</div>
            </div>
            <div className="download-invoice">
              <button className="download-btn btn" onClick={printDocument}>
                <img src="/assets/icons/supplier/Download.svg" alt="" />
                <span>Download Invoice</span>
              </button>
              <button
                className="download-btn btn"
                onClick={() => setPaidModal(!paidModal)}
                disabled={invoice?.isReceived || paidBtn}
              >
                Approve Invoice
              </button>
            </div>
          </div>
          <div id="divToPrint">
            <div className="block-container mt-4 ">
              <div className="top-section flex-view mx-5">
                {/* logo */}
                <div className="ag-logo">
                  <img src={AGLogo} alt="" />
                </div>

                {/* from section */}
                <div className="from-section my-3">
                  <div className="d-flex justify-content-start mb-4">
                    <div className="vertical-line"></div>
                    <div className="black-text mx-3">From</div>
                  </div>
                  <div className="flex-view my-2">
                    <div className="grey-text mr-2">Name:</div>
                    <div className="black-text">{getFromInfo()?.name}</div>
                  </div>
                  <div className="flex-view my-2">
                    <div className="grey-text mr-2">ABN:</div>
                    <div className="black-text">{getFromInfo()?.ABN}</div>
                  </div>
                  <div className="flex-view my-2">
                    <div className="grey-text mr-2">Address:</div>
                    <div className="black-text">{getFromInfo()?.address}</div>
                  </div>
                </div>

                {/* to section */}
                <div className="to-section my-3">
                  <div className="d-flex justify-content-start mb-4">
                    <div className="vertical-line"></div>
                    <div className="black-text mx-3">To</div>
                  </div>
                  <div className="flex-view my-2">
                    <div className="grey-text mr-2">Name:</div>
                    <div className="black-text ">{getToInfo()?.name}</div>
                  </div>
                  <div className="flex-view my-2">
                    <div className="grey-text mr-2">ABN:</div>
                    <div className="black-text ">{getToInfo()?.ABN}</div>
                  </div>
                  <div className="flex-view my-2">
                    <div className="grey-text mr-2">Address:</div>
                    <div className="black-text ">{getToInfo()?.address}</div>
                  </div>
                </div>

                {/* invoice section */}
                <div className="invoice-section my-3">
                  <div className="black-text">Invoice Date</div>
                  <div className="grey-text">
                    {moment(invoice?.completion_date).format('DD-MM-YYYY')}
                  </div>
                </div>
              </div>

              <div className="invoice-text">
                <span className="mx-2 p-2"> Invoice details</span>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column flex-lg-row flex-md-row tender-text  p-2 ">
              <div className="d-flex flex-row flex-lg-column flex-md-column justify-content-between align-items-center">
                <div className="grey-text tender-text ">Tender Number</div>
                <div className="black-text mx-2">
                  #{getTenderID(invoice?.tender_id[0])}
                </div>
              </div>

              <div className="d-flex flex-row flex-lg-column flex-md-column justify-content-between align-items-center">
                <div className="grey-text tender-text">Title</div>
                <div className="black-text ">{invoice?.tender_title}</div>
              </div>

              <div className="d-flex flex-row flex-lg-column flex-md-column justify-content-between align-items-center">
                <div className="grey-text tender-text">
                  Tender tax Invoice Total
                </div>
                <div className="black-text">
                  <input value={price} type="number" onChange={changePrice} />
                </div>
              </div>

              <div className="d-flex flex-row flex-lg-column flex-md-column justify-content-between align-items-center">
                <div className="grey-text tender-text">Service Fee</div>
                <div className="black-text">0.5%</div>
              </div>

              <div className="d-flex flex-row flex-lg-column flex-md-column justify-content-between align-items-center">
                <div className="grey-text">Total</div>
                <div className="black-text mb-2">${getAmount().toFixed(2)}</div>
              </div>
            </div>
            <div className="grey-text gst-text my-4 mx-2">Fee includes GST</div>
            <div className="amount-text">
              <span className="mx-2 p-2 my-2">Total Due</span>
              <span className="mx-2">
                $
                {invoice?.tender_payment_option[0] === PAYMENT_OPTIONS.DIRECT
                  ? getAmount().toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
          <div>
            {invoice?.tender_payment_option[0] === PAYMENT_OPTIONS.DIRECT && (
              <>
                <h2 className="mt-3">Bank Details</h2>
                <Row className="my-2">
                  <Col md="2" className="grey-text mr-2">
                    Name:
                  </Col>
                  <Col md="3" className="black-text">
                    {getFromInfo()?.name}
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md="2" className="grey-text mr-2">
                    BSB:
                  </Col>
                  <Col md="3" className="black-text">
                    {getFromInfo()?.BSB}
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md="2" className="grey-text mr-2">
                    Account:
                  </Col>
                  <Col md="3" className="black-text">
                    {getFromInfo()?.account}
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md="2" className="grey-text mr-2">
                    Reference:
                  </Col>
                  <Col md="3" className="black-text">
                    #{getTenderID(invoice?.tender_id[0])}
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default QuoteInvoice;
