import React from 'react';
import { Modal } from 'react-bootstrap';
import './ViewAttachment.css';

function ViewAttachment({ show, handleClose, file }) {
  return (
    <>
      <Modal size="xl" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            {file?.mimeType?.startsWith('image') ? (
              <img
                src={file?.url}
                alt="preview"
                className="enlarge-attachment"
              />
            ) : (
              <iframe
                title="attachment"
                className={file?.mimeType}
                width="100%"
                height="600"
                frameborder="0"
                src={`https://docs.google.com/gview?url=${file?.url}&embedded=true`}
              ></iframe>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ViewAttachment;
