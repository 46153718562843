import React, { useState } from 'react';
import './quotes.css';
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Row,
  Input,
} from 'reactstrap';
// core components
import { fetchQuotes } from 'services/adminService';
import * as moment from 'moment';
import SpinnerLoader from 'components/Misc/Spinner';
import FilterModal from 'components/Modals/FilterModal';
import { generateUniqueId } from 'services/utilities';
import { getTenderID } from 'services/utilities';
import { IMAGES_ASSESTS_PATH } from 'shared/constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import editIcon from '../../assets/img/icons/Edit.svg';

function Quotes(props) {
  const [quotes, setQuotes] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showSpinner, setSpinner] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState({});
  const history = useHistory();

  React.useEffect(() => {
    fetchQuote({
      id: props.id,
      pageNo: currentPage,
      size: pageSize,
      ...(search.trim() && {
        search: search.trim(),
      }),
      ...(filters.fromDate && {
        fromDate: filters.fromDate,
      }),
      ...(filters.toDate && {
        toDate: filters.toDate,
      }),
      ...(filters.zipCode && {
        zipCode: filters.zipCode,
      }),
      ...(filters.range && {
        range: filters.range,
      }),
      ...(filters.status && {
        status: filters.status,
      }),
    });
  }, [filters]);

  const fetchQuote = async (body) => {
    setSpinner(true);
    fetchQuotes(props.id, body)
      .then((data) => {
        setQuotes(data.data);
        setCount(data.meta.total_count);
        setSpinner(false);
      })
      .catch((err) => console.log(err));
  };

  const pages = () => {
    let pagesArr = [];
    for (let i = 1; i <= Math.ceil(count / pageSize); i++) {
      pagesArr.push(
        <PaginationItem className={currentPage === i ? 'active' : ''}>
          <PaginationLink onClick={(e) => handlePageClick(e, i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return pagesArr;
  };

  const handlePageClick = (e, pageNumber) => {
    e.preventDefault();
    fetchQuote({
      pageNo: pageNumber,
      size: pageSize,
      ...(search.trim() && {
        search: search.trim(),
      }),
    });
    setPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    fetchQuote({
      pageNo: currentPage,
      size: pageSize,
      search: e.target.value.trim(),
    });
  };

  const onEntriesSelect = (e) => {
    setPageSize(parseInt(e.target.value));
    fetchQuote({
      pageNo: currentPage,
      id: tenderId,
      size: pageSize,
      ...(search.trim() && {
        search: search.trim(),
      }),
    });
  };

  const handleFilters = () => {
    setShowModal(!showModal);
  };

  const quoteStatusColor = (status) => {
    const colors = {
      Active: '#42A5F5',
      Pending: '#FFCA28',
      'In Progress': '#FF7043',
      Hired: '#66BB6A',
      Closed: '#BDBDBD',
    };
    return colors[status];
  };

  const tableHeadData = [
    'SUPLLIER NAME',
    'TEDER NUMBER',
    'TENDER NAME',
    'CREATED AT',
    'AMOUNT',
    'STATUS',
    'ACTION',
  ];
  return (
    <div>
      <SpinnerLoader showSpinner={showSpinner} />
      <div className='quote-container my-5'>
        {showModal && (
          <FilterModal
            showModal={showModal}
            handleClose={handleFilters}
            setFilters={setFilters}
            statusId={5}
          />
        )}
        <div className='mt-4'>
          <Row>
            <div className='col'>
              <Card>
                <CardHeader className='border-0'>
                  {/* card header filter action and tender listing heading */}
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='tender-listing-heading'>Quote Listings</div>
                    <div>
                      <button
                        className='btn border border-dark'
                        onClick={handleFilters}
                      >
                        <img src='/dashboard-icons/Filters.svg' /> Filters
                      </button>
                    </div>
                  </div>

                  {/* Card header 2nd row for page entries and search action */}
                  <div className='mt-3 d-flex align-items-center justify-content-between'>
                    <div className='pageEntry'>
                      <small>
                        Show
                        <select
                          defaultValue={10}
                          onChange={onEntriesSelect}
                          className='pl-1 pr-3 ml-1'
                        >
                          <option value={10}>10</option>
                          <option value={15}>15</option>
                          <option value={20}>20</option>
                        </select>
                        <i className='fa fa-sort text-xxs'></i>
                        <span className='ml-2'>entires</span>
                      </small>
                    </div>

                    <div className='d-flex align-items-center'>
                      <div className='d-inline-block mr-4 search-color'>
                        Search:
                      </div>
                      <Input
                        className='d-inline-block listing-search-box'
                        placeholder='Search Title'
                        type='text'
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                </CardHeader>

                <div className='table-responsive'>
                  <Table className='dataTable align-items-center'>
                    <thead className='table-head icon-color-light'>
                      <tr>
                        {tableHeadData.map((header) => {
                          return (
                            <th
                              key={generateUniqueId()}
                              className='px-0 w-10 border'
                              scope='col'
                            >
                              {header}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className='list'>
                      {quotes.map((quote, index) => (
                        <tr
                          key={generateUniqueId()}
                          style={{
                            background: index % 2 == 0 ? '#EDF5EA4D' : 'white',
                          }}
                        >
                          <td className='pl-2'>
                            <div className='overflowStyle d-flex justify-content-start align-items-center farmer-name'>
                              <img
                                src={
                                  quote?.user?.profileUrl ||
                                  `${IMAGES_ASSESTS_PATH}/Profle-Image.svg`
                                }
                                className='mr-1 small-icon profile-pic'
                                alt='dp'
                              />
                              {quote?.user?.name}
                            </div>
                          </td>
                          <td className='text-dark overflowStyle px-1'>
                            #{getTenderID(props.id)}
                          </td>
                          <td className='text-dark overflowStyle px-1'>
                            {props?.title}
                          </td>
                          <td className='px-1'>
                            {moment(quote.createdAt).utc().format('DD-MM-YYYY')}
                          </td>
                          <td className='overflowStyle px-1'>{quote.price}</td>
                          <td className='px-1'>
                            <span
                              style={{
                                backgroundColor:
                                  quoteStatusColor(quote.status) || 'blue',
                              }}
                              className='quote-status-dot d-inline-block'
                            ></span>
                            <span className='d-inline-block'>
                              {quote.status.toString()}
                            </span>
                          </td>
                          <td className='pr-2'>
                            <div>
                              <img
                                className='tendersImageTable'
                                alt='...'
                                src={editIcon}
                                onClick={async () => {
                                  history.push(
                                    `/admin/quote-update/${quote._id}`
                                  );
                                }}
                                role={'button'}
                              />
                              <img
                                className='tendersImageTable'
                                alt='...'
                                src='/dashboard-icons/View.svg'
                                onClick={() => {
                                  props.handleQuoteId(quote._id);
                                  props.handleClick(3);
                                }}
                                role={'button'}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                      <tr></tr>
                    </tbody>
                  </Table>
                  {!showSpinner && !quotes.length && (
                    <div className='d-flex align-items-center justify-content-center mb-3'>
                      No records found
                    </div>
                  )}
                </div>
                <CardFooter className=''>
                  <Pagination
                    className='pagination justify-content-end mb-0'
                    listClassName='justify-content-end mb-0'
                  >
                    <PaginationItem
                      className={currentPage === 1 ? 'disabled' : ''}
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, currentPage - 1)}
                        tabIndex='-1'
                      >
                        <i className='fas fa-angle-left' />
                        <span className='sr-only'>Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {pages().map((page) => (
                      <div key={generateUniqueId()}>{page}</div>
                    ))}
                    <PaginationItem
                      className={
                        currentPage >= Math.ceil(count / pageSize)
                          ? 'disabled'
                          : ''
                      }
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, currentPage + 1)}
                      >
                        <i className='fas fa-angle-right' />
                        <span className='sr-only'>Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Quotes;
