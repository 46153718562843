import React, { useEffect } from 'react';
import classnames from 'classnames';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
import AuthHeader from 'components/Headers/AuthHeader.js';
import { login, verifyLogin } from 'services/authService';
import { useHistory } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { errorToast, updateToast } from 'shared/constants';
import SpinnerLoader from 'components/Misc/Spinner';

function Login() {
  const history = useHistory();

  useEffect(() => {
    verifyLogin()
      .then((verified) => {
        if (verified) {
          history.push('/admin/dashboard');
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showSpinner, setSpinner] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);

  const handleLogin = async () => {
    setSpinner(true);
    if (!email || !password) {
      toast.error('Provide Credentials to Continue', updateToast);
      setSpinner(false);
      return;
    }
    login(email, password)
      .then(() => {
        setSpinner(false);
        history.push('/admin/dashboard');
      })
      .catch(() => {
        toast.error('Invalid Email or Password', errorToast);
        setSpinner(false);
      });
  };

  const keyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleLogin();
    }
  };

  return (
    <>
      <SpinnerLoader adminView={true} showSpinner={showSpinner} />
      <AuthHeader />
      <div className="loginForm">
        <Container className="mt-n18 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-default-login border-0 mb-0">
                <CardBody className="px-lg-4 py-lg-3">
                  <div className="d-flex justify-content-between">
                    <div className=" mt-4 mb-4">
                      <h1 className="default-font">Welcome Back!</h1>
                      <p>Enter your credentials to get started</p>
                    </div>
                  </div>
                  <Form role="form">
                    <FormGroup
                      className={classnames('mb-3', {
                        focused: focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          onFocus={() => setfocusedEmail(true)}
                          onBlur={() => setfocusedEmail(true)}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onKeyDown={keyDown}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames('mb-1', {
                        focused: focusedPassword,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          onFocus={() => setfocusedPassword(true)}
                          onBlur={() => setfocusedPassword(true)}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyDown={keyDown}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="d-flex justify-content-between mt-3">
                      <div>
                        <input
                          type="checkbox"
                          className="success-color"
                          value={rememberMe}
                        />
                        <small className="success-color px-2" role={'button'}>
                          Remember me
                        </small>
                      </div>
                      <div>
                        <small
                          onClick={(e) => history.push('/user/forgot_password')}
                          className="success-color px-2 text-right"
                          role={'button'}
                        >
                          <u>Forgot Password?</u>
                        </small>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        className="default-button-background my-4"
                        color="info"
                        type="button"
                        onClick={handleLogin}
                      >
                        Login
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Toaster />
    </>
  );
}

export default Login;
