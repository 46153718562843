export const customIcons = {
  play: <i className="fa fa-play playIcon"></i>,
  pause: <i className="fa fa-pause playIcon"></i>,
};

export const IMAGES_ASSESTS_PATH = '/images';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const updateToast = {
  className: 'updateToast',
  iconTheme: {
    primary: 'white',
    secondary: '#3a3a73',
  },
};

export const successToast = {
  className: 'successToast',
  iconTheme: {
    primary: 'white',
    secondary: '#76d89c',
  },
};

export const errorToast = {
  className: 'errorToast',
  iconTheme: {
    primary: 'white',
    secondary: 'red',
  },
};
//styles for the webview in mobile phones
export const fontClasses = `<style> * {font-family: sans-serif;} .ql-font-serif, .ql-font-serif * { font-family: serif !important; } .ql-font-san-serif, .ql-font-san-serif * { font-family: sans-serif !important; } .ql-font-monospace, .ql-font-monospace * { font-family: monospace !important; } </style>`;

export const TENDER_STATUSES = {
  active: 'Active',
  pending: 'Pending',
  inProgress: 'In Progress',
  completed: 'Completed',
  closed: 'Closed',
  draft: 'Draft',
  dispute: 'Dispute',
};

export const defaultPageMeta = {
  current_page: 1,
  total_pages: 1,
  total_count: 0,
  per_page: 5,
};

export const NOTIFICATIONS_TYPES = {
  farmer_new_quote: 'FARMER_NEW_QUOTE',
  farmer_tax_invoice: 'FARMER_TAX_INVOICE',
  farmer_quote_updated: 'FARMER_QUOTE_UPDATED',
  pass_changed: 'PASS_CHANGED',
  new_tender: 'NEW_TENDER',
  supplier_quote_accepted: 'SUPPLIER_QUOTE_ACCEPTED',
  supplier_invoice_approved: 'SUPPLIER_INVOICE_APPROVED',
  mark_disputed: 'MARKED_DISPUTED',
  dispute_resolved: 'DISPUTE_RESOLVED',
  funds_transferred: 'FUNDS_TRANSFERRED',
};

export const passwordRegex = /^(?=.*[A-Z])(?=.*[@#$!%*?&-]).{8,}$/;

const passSpecialCharsRegex = /(?=.*[@#$!%*?&-])/;

const passCaseRegex = /(?=.*[A-Z])/;

export const checkPassChars = (password, confirmPassword) =>
  (password ? password.length >= 8 : false) &&
  (confirmPassword ? confirmPassword.length >= 8 : false);

export const checkPassSpecialChars = (password, confirmPassword) =>
  (password ? passSpecialCharsRegex.test(password) : false) &&
  (confirmPassword ? passSpecialCharsRegex.test(confirmPassword) : false);

export const checkPassCase = (password, confirmPassword) =>
  (password ? passCaseRegex.test(password) : false) &&
  (confirmPassword ? passCaseRegex.test(confirmPassword) : false);

export const PAYMENT_OPTIONS = {
  DIRECT: 'Pay Supplier Direct',
  THROUGH_AG: 'Pay through iinputs.com',
};
