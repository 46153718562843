import React from 'react';
import './Modal.css';
import { Modal, Button, closeButton } from 'react-bootstrap';

const PasswordUpdatedModal = ({ handleClose, showModal, email, password }) => {
  const copyPassword = async () => {
    await navigator.clipboard.writeText(password);
  };

  return (
    <Modal show={showModal} onHide={handleClose} className="mt-5">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="">
        <div className="text-center">
          <img src="/dashboard-icons/Success.svg" alt="" className="mb-2" />
          <div className="modal-title my-2">Password Updated</div>
          <div className="modal-text">
            We've sent a verification email to {email}.
            <div className="link-text">
              User can use that link to set their new password
            </div>
            <div>
              If you have trouble finding your email, check your spam folder for
              an email from noreply@example.com
            </div>
          </div>
        </div>
        <div className="text-center my-3">
          <Button className="btn copy-btn" onClick={copyPassword}>
            <span>
              <img
                src="/dashboard-icons/Copy-green.svg"
                alt=""
                className="mx-2"
                role="button"
              />
            </span>
            Copy Password to the clipboard
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PasswordUpdatedModal;
